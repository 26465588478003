import { useIntl } from "react-intl";

import { cn } from "utils/style.utils";

import { useIsDesktop } from "hooks";
import { ThoughtProvokingQuestion } from "types/talk.types";

import Wrapper from "./Wrapper";

type Props = {
  thoughtProvokingQuestions: Array<ThoughtProvokingQuestion>;
};

const ThoughtProvokingQuestions = ({ thoughtProvokingQuestions }: Props) => {
  const isDesktop = useIsDesktop();
  const intl = useIntl();

  return (
    <Wrapper
      title={intl.formatMessage({
        id: "talk_detail.ai_content.thought_provoking_questions.title",
      })}
    >
      <div>
        {thoughtProvokingQuestions.map((question, idx) => (
          <div
            className={cn(
              "flex flex-col gap-2 border-b border-primary px-4 py-6",
              isDesktop && "last:border-b-0",
            )}
            key={idx}
          >
            <div className="flex flex-row items-center justify-between gap-4">
              <p className="mr-10">{question.text}</p>
              <span
                className="min-h-min w-10 text-[8.55555rem] italic leading-[100%] text-transparent md:mr-4"
                style={{
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  WebkitTextStrokeWidth: 1,
                  WebkitTextStrokeColor: "#E63223",
                }}
              >
                <div className="relative right-5">{idx + 1}</div>
              </span>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default ThoughtProvokingQuestions;
