import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import { useIonRouter } from "@ionic/react";
import { useEffect } from "react";

type Props = {
  statusBar: string;
  navigationBar: string;
  currentRoute: string;
  iconsColor: Style;
};

export const useColorSafeAreas = ({
  statusBar,
  navigationBar,
  currentRoute,
  iconsColor,
}: Props) => {
  const router = useIonRouter();
  useEffect(() => {
    if (
      router.routeInfo.pathname.startsWith(currentRoute) &&
      Capacitor.getPlatform() === "android"
    ) {
      StatusBar.setStyle({ style: iconsColor }); // Android status bar style
      StatusBar.setBackgroundColor({ color: statusBar }); // Android status bar color
      NavigationBar.setColor({ color: navigationBar }); // Android navigation bar color
    }
  }, [
    router.routeInfo.pathname,
    statusBar,
    navigationBar,
    currentRoute,
    iconsColor,
  ]);
};
