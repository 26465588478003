import { Animation, createAnimation } from "@ionic/react";
import { useEffect, useRef } from "react";

type Props = {
  isOpen: boolean;
  initialWidth?: number;
};

const ReactionDivider = ({ isOpen, initialWidth = 0 }: Props) => {
  const dividerEl = useRef<HTMLDivElement | null>(null);
  const animation = useRef<Animation | null>(null);

  useEffect(() => {
    if (animation.current === null && dividerEl.current) {
      animation.current = createAnimation()
        .addElement(dividerEl.current)
        .duration(400)
        .delay(150)
        .iterations(1)
        .easing("ease-out")
        .keyframes([
          { offset: 0, width: `${initialWidth}%` },
          { offset: 1, width: "100%" },
        ]);
    }
  }, [dividerEl, initialWidth]);

  useEffect(() => {
    if (isOpen && animation.current) {
      animation.current.play();
    }
  }, [isOpen]);

  return (
    <div className="flex h-[3px] items-center justify-center">
      <div ref={dividerEl} className="h-full bg-black" />
    </div>
  );
};

export default ReactionDivider;
