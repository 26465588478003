import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { useUser } from "queries/users";
import { UserQueries } from "queries/users/queries";

import { DiscoBallQueries } from "./queries";

export const useDiscoBallAndUserTiles = (
  config?: ReturnType<typeof DiscoBallQueries.getDiscoBallTiles>,
) => {
  const queryClient = useQueryClient();

  const {
    data: discoBallTiles,
    status,
    isLoading,
    error,
    isStale,
    refetch: refetchTiles,
  } = useQuery({
    ...DiscoBallQueries.getDiscoBallTiles(),
    ...config,
  });
  const { data: currentUser } = useUser();

  const mergedTiles = useMemo(() => {
    if (!discoBallTiles) return undefined;
    if (!currentUser?.discoballTile) return discoBallTiles;

    return [
      currentUser.discoballTile,
      ...discoBallTiles.filter(
        (tile) => tile.imageUrl !== currentUser.discoballTile?.imageUrl,
      ),
    ];
  }, [currentUser?.discoballTile, discoBallTiles]);

  const refetch = useCallback(() => {
    if (isStale) {
      refetchTiles();
      queryClient.invalidateQueries({
        queryKey: UserQueries.getUser().queryKey,
      });
    }
  }, [isStale, refetchTiles, queryClient]);

  return {
    data: mergedTiles,
    userMirrorIndex: currentUser?.discoballTile ? 0 : -1, // we can hard code 0 as the mirror index because we put it to the first place in the mergedTiles array
    status,
    isLoading,
    error,
    refetch,
  };
};
