import { Style } from "@capacitor/status-bar";
import {
  IonContent,
  IonPage,
  IonRouterLink,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import { lazy, Suspense, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useLocation } from "react-router";

import { cn } from "utils/style.utils";

import { ENABLE_COMMUNITY, ENABLE_LOGIN } from "constants/app.constants";
import { Routes } from "constants/routes.constants";
import { useColorSafeAreas, useIsDesktop } from "hooks";
import { useUser } from "queries";
import { useDiscoBallAndUserTiles } from "queries/discoball/useDiscoBallAndUserTiles";
import { discoView, profileView } from "services/avo/avo";
import {
  DiscoBallTileResponse,
  DiscoBallTileType,
} from "types/discoball.types";

import { DiscoBallCanvas } from "components/@ball";
import { PresentationControlHandle } from "components/@ball/PresentationControls";
import { FooterButton } from "components/@common/FooterButton";
import { HomeBottomLinks } from "components/@home";

const HomeLottieAnimation = lazy(
  () => import("components/@home/HomeLottieAnimation"),
);

const HomePage = () => {
  const router = useIonRouter();
  const intl = useIntl();
  useColorSafeAreas({
    currentRoute: Routes.Home,
    statusBar: "#000000",
    navigationBar: "#D2B4FF",
    iconsColor: Style.Dark,
  });

  const {
    data: mirrors,
    userMirrorIndex,
    refetch,
  } = useDiscoBallAndUserTiles();
  const isDesktop = useIsDesktop();
  const { pathname } = useLocation();

  const { data: user, isLoading } = useUser();

  const handleTileClick = (mirror?: DiscoBallTileResponse) => {
    if (!mirror) {
      if (!ENABLE_LOGIN) return;

      if (user?.id) {
        router.push(generatePath(Routes.User, { userId: user.id }));
      } else {
        router.push(generatePath(Routes.Onboarding));
      }
      return;
    }

    switch (mirror.tileType) {
      case DiscoBallTileType.USER_CONTRIBUTION:
        if (!ENABLE_LOGIN || !ENABLE_COMMUNITY) break;
        if (mirror.entityId) {
          router.push(generatePath(Routes.User, { userId: mirror.entityId }));
        }
        if (user?.id !== mirror.entityId) profileView();
        break;
      case DiscoBallTileType.KEY_TAKEAWAY:
      case DiscoBallTileType.SPEAKER:
        if (mirror.entityId)
          router.push(generatePath(Routes.Talk, { talkId: mirror.entityId }));
        break;
      case DiscoBallTileType.PHOTO:
        break; // there is no action for photo tiles
    }
  };

  useIonViewDidEnter(() => {
    refetch();
  }, [refetch]);
  useIonViewDidEnter(() => {
    discoView();
  }, []);

  const discoballControls = useRef<PresentationControlHandle>(null);

  return (
    <IonPage>
      <IonContent className="h-full" fullscreen>
        <div className="relative py-safe">
          {!isDesktop ? (
            <Suspense fallback={null}>
              <HomeLottieAnimation />
            </Suspense>
          ) : (
            <img
              src="/assets/images/ubn-bg.svg"
              className="h-screen w-screen object-cover object-center"
            />
          )}
          <div
            className={cn(
              "fixed left-0 right-0 top-0 z-10 flex w-full flex-row justify-between md:top-14 md:w-fit md:flex-col md:justify-center md:pl-14",
              !isDesktop &&
                "transition-all duration-300 pt-safe pb-safe-offset-4",
            )}
          >
            <IonRouterLink
              routerLink={generatePath(Routes.Schedule)}
              routerDirection="forward"
              color="light"
              className="w-full p-4 uppercase underline md:w-fit md:px-4 md:py-1.5"
            >
              <FormattedMessage id="talks.title" />
            </IonRouterLink>

            <IonRouterLink
              routerLink={generatePath(Routes.N5Page)}
              routerDirection="forward"
              color="light"
              className="hidden w-fit whitespace-pre-line px-4 py-1.5 uppercase underline md:block md:whitespace-normal"
            >
              <FormattedMessage id="home.mx_tm.title" />
            </IonRouterLink>

            {ENABLE_COMMUNITY && (
              <IonRouterLink
                routerLink={generatePath(Routes.Community)}
                routerDirection="forward"
                color="light"
                className="p-4 uppercase underline"
              >
                <FormattedMessage id="community.title" />
              </IonRouterLink>
            )}
          </div>

          <div className="absolute inset-0 z-0 h-screen w-screen">
            {!!mirrors?.length && (
              <DiscoBallCanvas
                mirrors={mirrors}
                onClick={handleTileClick}
                userMirrorIndex={userMirrorIndex}
                visible={pathname === Routes.Home}
                presentationControlsRef={discoballControls}
              />
            )}
          </div>

          {isDesktop && (
            <div className="fixed bottom-14 left-14 hidden flex-col gap-2 md:flex">
              <button
                aria-label={intl.formatMessage({ id: "common.zoom_in" })}
                title={intl.formatMessage({ id: "common.zoom_in" })}
                onClick={() => discoballControls.current?.incrementZoom(2.5)}
                className="h-8 w-8 bg-neutral-50/50 transition-all duration-200 hover:bg-neutral-50 active:bg-neutral-50/90"
              >
                +
              </button>
              <button
                aria-label={intl.formatMessage({ id: "common.zoom_out" })}
                title={intl.formatMessage({ id: "common.zoom_out" })}
                onClick={() => discoballControls.current?.decrementZoom(2.5)}
                className="h-8 w-8 bg-neutral-50/50 transition-all duration-200 hover:bg-neutral-50 active:bg-neutral-50/90"
              >
                -
              </button>
            </div>
          )}
        </div>
      </IonContent>
      {!isDesktop && <HomeBottomLinks user={user} userIsLoading={isLoading} />}

      {!isDesktop && (
        <FooterButton
          bgColor="secondary"
          startIcon="/assets/icons/ico-AI.svg"
          endIcon="/assets/icons/ico-arrow-right.svg"
          onFooterClick={() =>
            router.push(generatePath(Routes.Chat), "forward")
          }
          textClass="text-[16px] line-clamp-1 break-all"
        >
          <FormattedMessage
            id={!!user ? "chatbot.ask.user" : "chatbot.ask.stranger"}
            values={{ name: isLoading ? "" : user?.firstName }}
          />
        </FooterButton>
      )}
    </IonPage>
  );
};

export default HomePage;
