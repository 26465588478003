import { useQuery } from "@tanstack/react-query";

import { DiscoBallQueries } from "./queries";

export const useDiscoBallTiles = (
  config?: Pick<
    ReturnType<typeof DiscoBallQueries.getDiscoBallTiles>,
    "refetchInterval"
  >,
) => {
  const { data, status, isLoading, error } = useQuery({
    ...DiscoBallQueries.getDiscoBallTiles(),
    ...config,
  });

  return {
    data,
    status,
    isLoading,
    error,
  };
};
