import { memo, ReactNode, useEffect, useRef, useState } from "react";

import { cn } from "utils/style.utils";

type Props = {
  firstText: ReactNode;
  secondText: ReactNode;
  isPlaying?: boolean;
  className?: string;
  textClass?: string;
};

const TextSlider = ({
  firstText,
  secondText,
  isPlaying = true,
  className,
  textClass,
}: Props) => {
  const firstTextEl = useRef<HTMLDivElement | null>(null);
  const secondTextEl = useRef<HTMLDivElement | null>(null);

  const [firstTextIsShowing, setFirstTextIsShowing] = useState(true);
  // Must use interval as iOS webview has problems firing CSS animations when loading page for the first time
  useEffect(() => {
    if (!isPlaying) return;
    const textSwitchInterval = setInterval(() => {
      setFirstTextIsShowing(!firstTextIsShowing);
    }, 3000);

    return () => clearInterval(textSwitchInterval);
  }, [firstTextIsShowing, isPlaying]);

  return (
    <div className={cn("relative overflow-hidden", className)}>
      <div
        ref={firstTextEl}
        className={cn(
          "absolute bottom-0 right-0 transition-all duration-500 ease-out",
          firstTextIsShowing ? "translate-y-0" : "-translate-y-full",
          textClass,
        )}
      >
        {firstText}
      </div>
      <div
        ref={secondTextEl}
        className={cn(
          "absolute bottom-0 right-0 transition-all duration-500 ease-out",
          firstTextIsShowing ? "translate-y-full" : "translate-y-0",
          textClass,
        )}
      >
        {secondText}
      </div>
    </div>
  );
};

export default memo(TextSlider);
