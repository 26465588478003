export const isDateInPast = (dateTime: string) => {
  const nowUtc = Date.now(); // Current time in UTC milliseconds
  const endDateUtc = new Date(dateTime).getTime(); // UTC milliseconds of the end date
  return endDateUtc < nowUtc;
};

/**
 * Returns the keys of an object while ensuring that the returned keys are typed
 */
export const objectKeys = <T extends object>(obj: T) =>
  Object.keys(obj) as Array<keyof T>;

/**
 * Converts a given title to a URL-friendly slug.
 *
 * @param title - The title to convert into a slug.
 * @returns A URL-friendly slug string.
 */
export const titleToSlug = (title: string) => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[\s\W-]+/g, "-") // Replace spaces and non-word characters with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
};
