import { Style } from "@capacitor/status-bar";
import { IonContent, IonPage, useIonViewDidEnter } from "@ionic/react";
import throttle from "lodash.throttle";
import { ReactNode, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { cn } from "utils/style.utils";

import { Routes } from "constants/routes.constants";
import {
  useColorSafeAreas,
  useIsDesktop,
  useShowHeaderTitleOnScroll,
} from "hooks";
import { novemberfiveView } from "services/avo/avo";

import { AppVersion } from "components/@common/AppVersion";
import { TitleHeader } from "components/@common/TitleHeader";
import { TransitionFly } from "components/@transitions";

const n5Images = [
  {
    src: "/assets/images/n5/n5_logo_img.jpg",
    alt: "November Five Logo",
  },
  {
    src: "/assets/images/n5/n5_mdlbeast_img.jpg",
    alt: "MDLBEAST",
  },
  {
    src: "/assets/images/n5/n5_nofomo_img.jpg",
    alt: "NoFomo",
  },
  {
    src: "/assets/images/n5/n5_base_img.jpg",
    alt: "Base",
  },
  {
    src: "/assets/images/n5/n5_vanbreda_img.jpg",
    alt: "Vanbreda",
  },
];

const N5Page = () => {
  useColorSafeAreas({
    currentRoute: Routes.N5Page,
    statusBar: "#ffffff",
    navigationBar: "#ffffff",
    iconsColor: Style.Light,
  });

  const intl = useIntl();
  const isDesktop = useIsDesktop();

  const titleRef = useRef<HTMLDivElement>(null);
  const { handleHeaderScroll, titleIsShown } = useShowHeaderTitleOnScroll(
    titleRef.current?.clientHeight || 200,
  );

  useIonViewDidEnter(() => {
    novemberfiveView();
  });

  return (
    <IonPage>
      <TitleHeader color="dark" bgColor="white">
        <TransitionFly show={titleIsShown} direction="bottom">
          <FormattedMessage id="n5_page.title" />
        </TransitionFly>
      </TitleHeader>
      <IonContent
        color="white"
        scrollEvents={!isDesktop}
        scrollY={!isDesktop}
        onIonScroll={throttle(handleHeaderScroll, 50)}
      >
        <div className={cn("flex", isDesktop ? "flex-row" : "flex-col")}>
          <div
            ref={titleRef}
            className="desktop-sidebar md:border-r md:border-black"
          >
            <h1
              className={cn(
                "h-fit whitespace-pre-line px-4 pt-4 text-3xl",
                isDesktop && "border-b border-black pb-4",
              )}
            >
              <FormattedMessage id="n5_page.title" />
            </h1>
          </div>

          <div
            className={cn(
              "flex flex-col md:h-screen md:w-fit md:overflow-y-auto md:pb-[62px]",
              isDesktop && "ion-content-scroll-host",
            )}
          >
            <div className="flex">
              <PageSection
                titleKey="n5_page.unforgettable_memories.title"
                contentKey="n5_page.unforgettable_memories.content"
                sectionClass="flex flex-col gap-4 border-b border-black px-4 pb-6 pt-4"
              />
              <div
                className={cn(
                  "flex h-auto max-h-full w-full min-w-96 items-center justify-center overflow-hidden",
                  !isDesktop && "hidden",
                )}
              >
                <img
                  src={n5Images[0].src}
                  alt={n5Images[0].alt}
                  className="aspect-square h-full w-full object-cover object-center"
                />
              </div>
            </div>
            <PageSection
              titleKey="n5_page.move_remember.title"
              contentKey="n5_page.move_remember.content"
            />
            <PageSection
              titleKey="n5_page.remember_you.title"
              contentKey="n5_page.remember_you.content"
            />
            <PageSection
              titleKey="n5_page.brand_experience.title"
              contentKey="n5_page.brand_experience.content"
            />
            <PageSection
              titleKey="n5_page.redefining_possible.title"
              contentKey="n5_page.redefining_possible.content"
            />
            <section className="px-4 py-6">
              <p className="text-lg font-black uppercase italic leading-[32.2px]">
                <FormattedMessage id="n5_page.cta" />
              </p>
            </section>
            <section
              className={cn("bg-black px-4 py-6", isDesktop && "order-last")}
            >
              <img
                src="/assets/images/n5/n5-logo.svg"
                alt={intl.formatMessage({ id: "n5_page.n5.name" })}
              />
              <p
                className={cn(
                  "mt-2 text-base font-normal leading-6 text-white",
                  isDesktop && "whitespace-pre-line",
                )}
              >
                <FormattedMessage id="n5_page.n5.description" />
              </p>
              <ul className="mt-6 flex flex-col gap-4">
                <ExternalLink
                  label={<FormattedMessage id="common.website" />}
                  link={intl.formatMessage({ id: "links.n5.site" })}
                />
                <ExternalLink
                  label={<FormattedMessage id="common.socials.instagram" />}
                  link={intl.formatMessage({ id: "links.n5.instagram" })}
                />
                <ExternalLink
                  label={<FormattedMessage id="common.socials.linkedin" />}
                  link={intl.formatMessage({ id: "links.n5.linkedin" })}
                />
              </ul>
            </section>
            <section
              className={cn(
                "flex",
                isDesktop
                  ? "grid min-h-[416px] grid-cols-2 border-y border-neutral lg:grid-cols-4"
                  : "flex-col",
              )}
            >
              {n5Images.map((image, index) => {
                if (isDesktop && index === 0) return null;
                return (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={cn(
                      "h-full w-full object-cover",
                      isDesktop && "min-w-60",
                    )}
                  />
                );
              })}
            </section>
          </div>
        </div>
        {!isDesktop && <AppVersion />}
      </IonContent>
    </IonPage>
  );
};

export default N5Page;

const PageSection = ({
  titleKey,
  contentKey,
  sectionClass,
}: {
  titleKey: I18nKey;
  contentKey: I18nKey;
  sectionClass?: string;
}) => {
  const isDesktop = useIsDesktop();
  return (
    <section
      className={cn(
        sectionClass || "flex flex-col gap-1 border-b border-black bg-white",
        isDesktop ? "p-6" : "px-4 pb-6 pt-2",
      )}
    >
      <h2
        className={cn(
          "text-xl font-medium leading-[32.2px]",
          isDesktop && "m-0 whitespace-pre-line",
        )}
      >
        <FormattedMessage
          id={titleKey}
          values={{
            strong: (value) => <span className="text-primary">{value}</span>,
          }}
        />
      </h2>
      <p className="whitespace-pre-line text-base font-normal leading-6">
        <FormattedMessage
          id={contentKey}
          values={{
            br: () => <br />,
          }}
        />
      </p>
    </section>
  );
};

const ExternalLink = ({ link, label }: { link: string; label: ReactNode }) => {
  return (
    <li>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="text-white underline"
      >
        {label}
      </a>
    </li>
  );
};
