import { queryOptions } from "@tanstack/react-query";

import { captureError } from "utils/sentry.utils";

import { authApiService } from "services";
import { StaleTime } from "services/react-query/types";

export const CHAT_QUERY_PREFIX = "chat-query";

const fetchChat = async (chatId: string) => {
  const { data: chat } = await authApiService.getChat(chatId);
  return chat.data;
};

export const ChatQueries = {
  getChatById: (id: string) =>
    queryOptions({
      queryKey: [CHAT_QUERY_PREFIX, id],
      queryFn: () =>
        fetchChat(id).catch((error) => {
          captureError("Error while getting chat by id", error);
          throw error;
        }),
      staleTime: StaleTime.FOREVER,
    }),
};
