import { forwardRef, memo } from "react";
import { FormattedMessage } from "react-intl";

import { STAGE_MAP } from "constants/event.constants";
import { useIsDesktop } from "hooks";
import { SpeakerResponse } from "types/speaker.types";

import { IntroSkeleton } from "../IntroSkeleton";
import { formatDateTimeRange } from "./utils";

type Props = {
  onScrollToAboutClick: () => void;
  speaker?: Pick<SpeakerResponse, "name" | "city" | "role">;
  talk?: Pick<
    SpeakerResponse,
    "startDatetime" | "endDatetime" | "stage" | "hasAIReflections"
  >;
  isLoading?: boolean;
};

const Intro = forwardRef<HTMLDivElement, Props>(function Intro(
  { onScrollToAboutClick, speaker, talk, isLoading },
  ref,
) {
  const isDesktop = useIsDesktop();

  if (isLoading || !speaker || !talk) return <IntroSkeleton />;
  return (
    <div
      ref={ref}
      className="desktop-sidebar px-4 pb-4 text-base md:border-r md:border-neutral md:pt-2"
    >
      <h1 className="mb-4 mt-2 whitespace-pre-line text-3xl font-medium">
        {speaker.name}
      </h1>

      <div className="mb-6 mt-4 flex flex-col gap-2 text-neutral-100">
        <p>{speaker.role}</p>
        <div className="flex flex-row items-center justify-between">
          <p>{speaker.city}</p>
          {(talk.hasAIReflections || !isDesktop) && (
            <p>
              <a
                className="cursor-pointer break-words text-base text-neutral underline"
                onClick={onScrollToAboutClick}
              >
                <FormattedMessage id="talk_detail.intro.about_speaker.title" />
              </a>
            </p>
          )}
        </div>
      </div>

      <div className="border border-neutral p-4 text-base">
        <p className="leading-7">
          {formatDateTimeRange(talk.startDatetime, talk.endDatetime)}
        </p>
        <p className="capitalize leading-7">
          {STAGE_MAP[talk.stage].toLocaleLowerCase()}
        </p>
      </div>
    </div>
  );
});

export default memo(Intro);
