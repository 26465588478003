import { Style } from "@capacitor/status-bar";
import { IonContent, IonPage, useIonViewDidEnter } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { cn } from "utils/style.utils";

import { Routes } from "constants/routes.constants";
import { useColorSafeAreas, useIsDesktop } from "hooks";
import { useIsMount } from "hooks/useIsMount";
import { useSpeakers } from "queries/speaker/useSpeakers";
import { talksOverviewView } from "services/avo/avo";
import { EventDay } from "types/event.types";

import { TitleHeader } from "components/@common/TitleHeader";
import { Nav } from "components/@schedule/Nav";
import { Speakers } from "components/@schedule/Speakers";

import { getDefaultSelectedDay } from "./utils";

const DEFAULT_SELECTED_DAY = getDefaultSelectedDay();

const Schedule = () => {
  useColorSafeAreas({
    currentRoute: Routes.Schedule,
    statusBar: "#e63223",
    navigationBar: "#e63223",
    iconsColor: Style.Dark,
  });
  const contentRef = useRef<HTMLIonContentElement>(null);
  const isMount = useIsMount();
  const isDesktop = useIsDesktop();

  const { data, isLoading } = useSpeakers();
  const [selectedDay, setSelectedDay] =
    useState<EventDay>(DEFAULT_SELECTED_DAY);

  useIonViewDidEnter(() => {
    talksOverviewView();
  });

  useEffect(() => {
    // for the first time, we don't want to scroll to top
    // because auto scroll will happen based on the current time
    if (!isMount) contentRef.current?.scrollToTop(0);
  }, [selectedDay, contentRef, isMount]);

  return (
    <IonPage>
      <TitleHeader color="black" bgColor="primary" className="md:hidden">
        <FormattedMessage id="talks.title" />
      </TitleHeader>

      <IonContent ref={contentRef} scrollY={!isDesktop} color="primary">
        <div className="relative md:flex">
          <Nav selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
          <div
            className={cn(
              "relative w-full md:h-screen md:w-fit md:overflow-y-auto",
              isDesktop && "ion-content-scroll-host",
            )}
          >
            <Speakers
              isLoading={isLoading}
              speakers={data && data[selectedDay]}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Schedule;
