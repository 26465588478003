import { useIntl } from "react-intl";

import { Challenge } from "types/talk.types";

import Wrapper from "./Wrapper";

const Challenges = ({ challenges }: { challenges: Array<Challenge> }) => {
  const intl = useIntl();

  return (
    <Wrapper
      title={intl.formatMessage({
        id: "talk_detail.ai_content.challenges.title",
      })}
    >
      <div>
        {challenges.map((challenge, idx) => (
          <div
            className="flex flex-col gap-2 border-b border-primary px-4 py-6"
            key={idx}
          >
            <div className="flex flex-col">
              <h2
                className="text-lg font-black italic leading-[32px] tracking-[0.03rem] text-transparent"
                // tailwind does not support fontFeatureSettings, WebkitTextStrokeWidth and WebkitTextStrokeColor
                style={{
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  WebkitTextStrokeWidth: 1,
                  WebkitTextStrokeColor: "#E63223",
                }}
              >
                {challenge.title}
              </h2>
              <div>{challenge.content}</div>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Challenges;
