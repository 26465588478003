/**
 * Converts a date string to the desire format to align with the design
 *
 * @param {string} startDatetime - The ISO 8601 formatted start datetime string.
 * @param {string} endDatetime - The ISO 8601 formatted end datetime string.
 * @returns {string} A formatted string representing the day and time range.
 *
 * @example
 * // Returns "Friday, 9PM - 9:45PM"
 * formatDateTimeRange("2024-09-27T21:00:00.000Z", "2024-09-27T21:45:00.000Z");
 */
export const formatDateTimeRange = (
  startDatetime: string,
  endDatetime: string,
) => {
  const dayOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const start = new Date(startDatetime);
  const end = new Date(endDatetime);

  // Format the day
  const dayString = start.toLocaleDateString("en-GB", dayOptions);

  // Format the time
  const startHour = new Intl.DateTimeFormat("en-GB", timeOptions).format(start);
  const endHour = new Intl.DateTimeFormat("en-GB", timeOptions).format(end);

  // Manually format the time to remove spaces in AM/PM
  const formatTime = (timeString: string) => {
    const [time, period] = timeString.split(" ");
    return `${time.toUpperCase()}${period.toUpperCase()}`;
  };

  return `${dayString}, ${formatTime(startHour)} - ${formatTime(endHour)}`;
};
