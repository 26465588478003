import { IonPage } from "@ionic/react";

import { useDiscoBallTiles } from "queries/discoball";
import { RefetchInterval } from "services/react-query/types";

import { DiscoBallCanvas } from "components/@ball";
import { Noise } from "components/@common/Noise";

const BoothPage = () => {
  const { data: mirrors } = useDiscoBallTiles({
    refetchInterval: RefetchInterval.MINUTE,
  });

  return (
    <IonPage className="h-[100vh] w-[100vw] bg-[#181818]">
      <Noise />
      <div className="absolute inset-0 z-0 h-screen w-screen">
        {!!mirrors?.length && (
          <DiscoBallCanvas mirrors={mirrors} z={6} rotationSpeed={0.5} />
        )}
      </div>
    </IonPage>
  );
};

export default BoothPage;
