import { useContext } from "react";
import { FormattedMessage } from "react-intl";

import { ChatbotUIContext } from "services/ai-chatbot";

export const ChatStopGenerating = () => {
  const { isLoading, firstTokenReceived, abortController } =
    useContext(ChatbotUIContext);
  return (
    <button
      onClick={() => abortController?.abort()}
      disabled={isLoading || !firstTokenReceived}
      className="mr-6 flex h-full flex-1 items-center gap-2 whitespace-nowrap p-0.5 text-[16px] font-normal text-primary transition-all duration-200 active:bg-primary/10 disabled:opacity-50"
    >
      <span className="pb-1">
        <FormattedMessage id="chatbot.generating.stop" />
      </span>
      <div className="h-5 w-5 bg-primary" />
    </button>
  );
};

type ClearChatButtonProps = {
  onClearChat: () => void;
};

export const ChatClearButton = ({ onClearChat }: ClearChatButtonProps) => {
  return (
    <button
      onClick={onClearChat}
      className="text-sm font-normal text-primary underline"
    >
      <FormattedMessage id="chatbot.clear" />
    </button>
  );
};
