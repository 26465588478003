import posthog from "posthog-js";

import { CustomDestination } from "./avo";

export const posthogDestination: CustomDestination = {
  logEvent: (eventName, eventProperties) => {
    posthog.capture(eventName, eventProperties);
  },
  identify: (userId) => {
    posthog.identify(userId);
  },
  setUserProperties: (_, userProperties) => {
    posthog.setPersonProperties(userProperties);
  },
};
