import { Canvas } from "@react-three/fiber";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { KernelSize, Resolution } from "postprocessing";
import { Ref } from "react";
import { Camera } from "three";

import { DiscoBallTileResponse } from "types/discoball.types";

import {
  BenchmarkProvider,
  BenchmarkTier,
  useBenchmarkTier,
} from "../BenckmarkProvider";
import { DiscoBall } from "../DiscoBall";
import { Environment } from "../Environment";
import { Lights } from "../Lights";
import {
  PresentationControlHandle,
  PresentationControls,
} from "../PresentationControls";

type ThreeCamera = Camera & { manual?: boolean };

interface DiscoBallProps {
  mirrors?: Array<DiscoBallTileResponse>;
  userMirrorIndex?: number;
  onClick?: (mirror?: DiscoBallTileResponse) => void;
  rotationSpeed?: number;
  presentationControlsRef?: Ref<PresentationControlHandle>;
}

const DiscoBallCanvas = ({
  mirrors,
  onClick,
  userMirrorIndex,
  rotationSpeed,
  presentationControlsRef,
}: DiscoBallProps) => {
  const tier = useBenchmarkTier();

  return (
    <>
      <Lights />
      <PresentationControls
        ref={presentationControlsRef}
        idleTime={4000}
        rotationSpeed={rotationSpeed}
      >
        <DiscoBall
          mirrors={mirrors}
          onClick={onClick}
          userMirrorIndex={userMirrorIndex}
        />
      </PresentationControls>
      {/* Environment map for reflection */}
      <Environment />

      {/* Postprocessing effects */}
      {tier === BenchmarkTier.High && (
        <EffectComposer>
          <Bloom
            intensity={1} // The bloom intensity.
            kernelSize={KernelSize.SMALL} // blur kernel size
            luminanceThreshold={0.9} // luminance threshold. Raise this value to mask out darker elements in the scene.
            luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
            mipmapBlur={false} // Enables or disables mipmap blur.
            resolutionX={Resolution.AUTO_SIZE}
            resolutionY={Resolution.AUTO_SIZE}
          />
        </EffectComposer>
      )}
    </>
  );
};
interface CanvasProps {
  setCamera?: (camera: ThreeCamera) => void;
  visible?: boolean;
  z?: number;
  presentationControlsRef?: Ref<PresentationControlHandle>;
}

const CanvasWrap = ({
  visible = true,
  z = 11,
  ...rest
}: DiscoBallProps & CanvasProps) => {
  return (
    <Canvas
      camera={{ fov: 45, position: [0, 0, z] }}
      dpr={[1, 2]}
      style={{ touchAction: "none" }}
      frameloop={visible ? "always" : "never"}
    >
      <BenchmarkProvider>
        <DiscoBallCanvas {...rest} />
      </BenchmarkProvider>
    </Canvas>
  );
};

export default CanvasWrap;
