import { AvoInspector } from "avo-inspector";

import config from "config";
import { PACKAGE_VERSION } from "config/version";

import { isDev, isProd, isStag } from "utils/env.utils";

import { AvoEnv, initAvo } from "./avo";
import { posthogDestination } from "./destination";

const getEnv = () => {
  switch (true) {
    case isProd():
      return "prod";
    case isStag():
      return "staging";
    default:
      return "dev";
  }
};

export const initializeAvo = () => {
  const inspector = new AvoInspector({
    apiKey: config.AVO_API_KEY,
    env: getEnv(),
    version: PACKAGE_VERSION,
    appName: "Us By Night",
  });

  initAvo(
    {
      env: isProd() ? AvoEnv.Prod : AvoEnv.Staging,
      webDebugger: false,
      inspector,
    },
    { frontEnv: isProd() ? "release" : isDev() ? "debug" : "beta" }, // Allowed values: debug, beta, release
    {},
    posthogDestination,
  );
};
