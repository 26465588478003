import { IonBackButton } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { EVENT_DAYS } from "constants/event.constants";
import { Routes } from "constants/routes.constants";
import { EventDay } from "types/event.types";

import NavItem from "./NavItem";

type Props = {
  selectedDay: EventDay;
  setSelectedDay: (day: EventDay) => void;
};

const Nav = ({ selectedDay, setSelectedDay }: Props) => {
  return (
    <nav className="desktop-sidebar sticky top-0 z-50 flex border-b border-neutral md:h-screen md:flex-col md:border-b-0 md:border-r md:bg-primary">
      <div className="flex h-fit w-full flex-col md:h-[120px]">
        <div className="hidden h-14 w-full items-center justify-between md:flex">
          <IonBackButton
            icon="/assets/icons/ico-close.svg"
            defaultHref={Routes.Home}
            mode="ios"
            type="button"
            color="black"
            text=""
            className="ml-2 !aspect-square"
          />
          <h1 className="text-base font-black uppercase italic text-black">
            <FormattedMessage id="talks.title" />
          </h1>
          <div className="w-10" />
        </div>
        <div className="grid w-full grid-cols-3 md:!min-h-[66px] md:border-y md:border-black">
          {EVENT_DAYS.map((day, idx) => (
            <NavItem
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              title={day}
              key={idx}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
