import { Share } from "@capacitor/share";
import { FormattedMessage } from "react-intl";

import { reflectionShare } from "services/avo/avo";

type Props = {
  title: string;
  text: string;
  dialogTitle?: string;
  trackShareEvent?: boolean;
};

const ShareButton = ({
  title,
  text,
  dialogTitle,
  trackShareEvent = false,
}: Props) => {
  const handleShare = async () => {
    await Share.share({
      title,
      text,
      url: "https://www.ubn.ai",
      dialogTitle,
    });
    if (trackShareEvent) reflectionShare();
  };

  return (
    <button
      onClick={handleShare}
      className="text-sm font-normal underline transition-all duration-200 active:bg-neutral-100/10"
    >
      <FormattedMessage id="common.share" />
    </button>
  );
};

export default ShareButton;
