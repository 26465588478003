import { IonSpinner, IonText } from "@ionic/react";
import { forwardRef, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { useCopyToClipboard } from "hooks";
import { ChatbotUIContext } from "services/ai-chatbot";
import { Message } from "types/chat.types";

import { MarkdownMessage } from "../MarkdownMessage";

const ChatList = forwardRef<HTMLDivElement>((_, ref) => {
  const { chatMessages } = useContext(ChatbotUIContext);

  return (
    <div className="flex h-full flex-col-reverse overflow-auto">
      <div ref={ref} className="h-[1px] w-full bg-transparent" />
      {chatMessages.toReversed().map((message, _i) => (
        <ChatMessage
          key={message.id}
          message={message}
          isLoading={!message.content}
        />
      ))}
    </div>
  );
});
ChatList.displayName = "ChatList";

export default ChatList;

interface ChatMessageProps {
  message: Message;
  isLoading?: boolean;
}
const ChatMessage = ({ message, isLoading }: ChatMessageProps) => {
  const { copy, isCopied } = useCopyToClipboard();

  return (
    <div className="flex flex-col gap-4 break-words border-t border-primary px-4 py-6">
      {isLoading && <IonSpinner name="crescent" color="primary" />}

      {!isLoading && (
        <IonText
          className={`w-full ${message.role === "user" ? "text-end" : "text-start"}`}
          color="primary"
        >
          {message.role === "user" ? (
            message.content
          ) : (
            <MarkdownMessage content={message.content} />
          )}
        </IonText>
      )}

      {!isLoading && message.role === "assistant" && (
        <button
          onClick={() => copy(message.content)}
          className={`w-full text-end text-sm font-normal text-primary ${!isCopied && "underline"}`}
        >
          <FormattedMessage
            id={isCopied ? "common.copied" : "chatbot.copy_to_clipboard"}
          />
        </button>
      )}
    </div>
  );
};
