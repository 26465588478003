import { queryOptions } from "@tanstack/react-query";

import { captureError } from "utils/sentry.utils";

import { apiService } from "services";
import { StaleTime } from "services/react-query/types";

export const DISCOBALL_QUERY_PREFIX = "discoball";

const fetchDiscoBallTiles = async () => {
  const { data: response } = await apiService.getDiscoBallTiles();

  return response.data;
};

export const DiscoBallQueries = {
  getDiscoBallTiles: () =>
    queryOptions({
      queryKey: [DISCOBALL_QUERY_PREFIX],
      queryFn: () =>
        fetchDiscoBallTiles().catch((error) => {
          captureError("Error while getting discoball tiles", error);
          throw error;
        }),
      staleTime: StaleTime.MINUTE,
    }),
};
