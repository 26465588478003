export const EVENT_DAYS = ["Thursday", "Friday", "Saturday"] as const; // Capitalized to be aligned with the return type of new Intl.DateTimeFormat("en-US", { weekday: "long" }).format()
export const EVENT_DATE_MAP = {
  "2024-09-26": "Thursday",
  "2024-09-27": "Friday",
  "2024-09-28": "Saturday",
} as const;

export const STAGE_MAP = {
  MAIN: "MAIN",
  LIVING_ROOM: "LIVING ROOM",
  SOOGOOD: "SOOGOOD",
  XYZ: "XYZ",
};
