import { EVENT_DATE_MAP } from "constants/event.constants";

/**
 * Gets the default selected day based on the current date for the event.
 * If the current date is not in the event date map, the default selected day is Thursday.
 */
export const getDefaultSelectedDay = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
  const day = today.getDate().toString().padStart(2, "0");
  const todayFormatted = `${year}-${month}-${day}`;

  return (
    EVENT_DATE_MAP[todayFormatted as keyof typeof EVENT_DATE_MAP] || "Thursday"
  );
};
