/* eslint-disable react/no-unknown-property */

import { ThreeEvent, useFrame } from "@react-three/fiber";
import { forwardRef, memo, useRef } from "react";
import {
  BufferGeometry,
  Group,
  Mesh,
  MeshStandardMaterial,
  NormalBufferAttributes,
  Quaternion,
  Vector3,
} from "three";

import { DelayedSuspense } from "../DelayedSuspense";
import { MirrorMaterial } from "../MirrorMaterial";

interface Props {
  texture?: string;
  geometry: BufferGeometry<NormalBufferAttributes>;
  onClick?: () => void;
  index: number;
  total: number;
  shiny?: boolean;
}

const Mirror = ({ texture, geometry, onClick, index, total, shiny }: Props) => {
  const meshRef = useRef<Group>(null);
  const materialRef = useRef<MeshStandardMaterial>(null);

  const handleSelect = () => {
    if (!onClick) return;

    if (materialRef.current) {
      materialRef.current.color.set("#aaa");
    }
  };

  const handleEnd = () => {
    if (materialRef.current) {
      materialRef.current.color.set("white");
    }
  };

  const handleClick = (event: ThreeEvent<MouseEvent>) => {
    if (event.detail !== 1) return;
    handleSelect();

    event.stopPropagation();

    setTimeout(() => {
      handleEnd();
      onClick?.();
    }, 200);
  };

  const handleFakeClick = (event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();
  };

  useFrame(() => {
    if (meshRef.current) {
      const initialPosition: Vector3 | undefined =
        geometry.userData.initialPosition;
      // We get the ball from the parent (the group of the ball) and that parent (the presentation controls)

      const ballRotation = meshRef.current?.parent?.parent?.rotation;

      // Create a quaternion from the Euler angles
      if (initialPosition && ballRotation) {
        const quaternion = new Quaternion().setFromEuler(ballRotation);

        // Rotate the initial position using the quaternion
        const rotatedPosition = initialPosition
          .clone()
          .applyQuaternion(quaternion);

        if (rotatedPosition.z <= 0.4) {
          meshRef.current.visible = false;
        } else {
          meshRef.current.visible = true;
        }
      }
    }
  });

  /*
  For performance reasons, we use a DelayedSuspense component to delay the rendering of the mirror.
  This way we can avoid rendering all the mirrors at once, which can be expensive.
  */
  const delay = texture ? ((index % (total / 20)) + 1) * 250 : 0;

  return (
    <group ref={meshRef} onClick={onClick ? handleClick : handleFakeClick}>
      <DelayedSuspense
        fallback={<EmptyMirror materialRef={materialRef} geometry={geometry} />}
        delay={delay}
      >
        {texture ? (
          <mesh geometry={geometry}>
            <MirrorMaterial ref={materialRef} texture={texture} shiny={shiny} />
          </mesh>
        ) : (
          <EmptyMirror materialRef={materialRef} geometry={geometry} />
        )}
      </DelayedSuspense>
    </group>
  );
};

interface EmptyMirrorProps {
  geometry: BufferGeometry<NormalBufferAttributes>;
  materialRef?: React.MutableRefObject<MeshStandardMaterial | null>;
}

const EmptyMirror = forwardRef<Mesh, EmptyMirrorProps>(
  ({ geometry, materialRef }, ref) => {
    return (
      <mesh geometry={geometry} ref={ref}>
        <meshStandardMaterial
          ref={materialRef}
          metalness={0.9}
          roughness={0.1}
        />
      </mesh>
    );
  },
);

EmptyMirror.displayName = "EmptyMirror";

export default memo(Mirror);
