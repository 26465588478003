import { isProd } from "utils/env.utils";

interface ENV_CONFIG {
  BUILD_ENV: string;
  NAME: string;
  VERSION: string;
  API_BASE: string;
  API_CLIENT_URL: string;
  API_SERVER_URL: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  POSTHOG_KEY?: string;
  POSTHOG_HOST?: string;
  AVO_API_KEY: string;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || "",
  VERSION: process.env.REACT_APP_VERSION || "",
  BUILD_ENV: process.env.REACT_APP_BUILD_ENV || "",

  // api
  API_BASE: process.env.REACT_APP_API_BASE || "",
  API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_URL || "",
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || "",

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || "",
  POSTHOG_KEY: process.env.REACT_APP_POSTHOG_KEY || "",
  POSTHOG_HOST: process.env.REACT_APP_POSTHOG_HOST || "",
  AVO_API_KEY: process.env.REACT_APP_AVO_API_KEY || "",

  // error tracking
  SENTRY: {
    dsn: "https://e8eab795cdc9ecccac313a9e84e2a1e3@o190235.ingest.us.sentry.io/4507933469900800",
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // dev
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? "",
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? "EUR",
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? "en-GB",
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? "en",
};

export default config;
