import { useMemo } from "react";

import { Emotions, Reaction } from "types/reaction.types";

const REACTIONS_LIMIT = 5;

export const useMostPopularReactions = (reactions: Array<Reaction>) => {
  const mostPopularReactions = useMemo(() => {
    if (!reactions.length) {
      return Object.values(Emotions)
        .slice(0, REACTIONS_LIMIT)
        .map((emotion) => ({
          emotion,
          count: 0,
        }));
    }

    const reactionsResult = reactions
      .sort((a, b) => b.count - a.count)
      .filter((reaction) => reaction.count > 0)
      .slice(
        0,
        reactions.length > REACTIONS_LIMIT ? REACTIONS_LIMIT : reactions.length,
      );

    if (reactionsResult.length < REACTIONS_LIMIT) {
      const remainingReactions = Object.values(Emotions)
        .filter(
          (emotion) => !reactionsResult.some((r) => r.emotion === emotion),
        )
        .slice(0, REACTIONS_LIMIT - reactionsResult.length)
        .map((emotion) => ({
          emotion,
          count: 0,
        }));
      return [...reactionsResult, ...remainingReactions];
    }

    return reactionsResult;
  }, [reactions]);

  return mostPopularReactions.sort((a, b) => {
    return a.emotion > b.emotion ? 1 : -1;
  });
};
