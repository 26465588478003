import { useQuery } from "@tanstack/react-query";

import { GetReactionsParams } from "types/reaction.types";

import { ReactionQueries } from "./queries";

export const useReactions = (
  params: GetReactionsParams,
  config?: ReturnType<typeof ReactionQueries.getReactions>,
) => {
  const { data, status, isLoading, error } = useQuery({
    ...ReactionQueries.getReactions(params),
    ...config,
  });

  return {
    data,
    status,
    isLoading,
    error,
  };
};
