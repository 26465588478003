import { useState } from "react";
import { useIntl } from "react-intl";

import { useToastNotification } from "./useToastNotification";

interface Props {
  onError?: (error?: unknown) => void;
  onSuccess?: () => void;
}

export const useCopyToClipboard = ({ onError, onSuccess }: Props = {}) => {
  const [isCopied, setIsCopied] = useState(false);
  const intl = useIntl();

  const copiedToClipboardToast = useToastNotification({
    message: intl.formatMessage({ id: "common.copied_to_clipboard" }),
    color: "medium",
  });

  const copy = async (text: string): Promise<boolean> => {
    if (isCopied) return false;
    if (!navigator?.clipboard) {
      onError?.();
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);

      onSuccess?.();

      setIsCopied(true);
      copiedToClipboardToast.presentToast();

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return true;
    } catch (error) {
      onError?.(error);
      return false;
    }
  };

  return { isCopied, copy };
};
