export enum Emotions {
  Hahaha = "HAHAHA",
  Boom = "BOOM",
  Crushin = "CRUSHIN",
  WaitWhat = "WAIT_WHAT",
  Zappp = "ZAPPP",
  AhaMoment = "AHA_MOMENT",
  Eggplant = "EGGPLANT",
}

export enum ReactionTypes {
  Reflection = "reflections",
  KeyTakeaway = "key-takeaways",
}

export type Reaction = {
  id?: number;
  emotion: Emotions;
  count: number;
};
export type PostReflectionReactionParams = {
  reflectionId: number;
  emotion: Emotions;
};
export type PostKeyTakeawayReactionParams = {
  keyTakeawayId: number;
  speakerId: number;
  emotion: Emotions;
};
export type PostReactionParams = {
  reflectionId?: number;
  keyTakeawayId?: number;
  speakerId?: number;
  emotion: Emotions;
};
export type GetReactionsParams = {
  type: ReactionTypes;
};
export type GetReactionsResponse = {
  reflectionId?: number;
  keyTakeawayId?: number;
  speakerId?: number;
  emotions: Array<Emotions>;
};
