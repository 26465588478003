import {
  CSSProperties,
  forwardRef,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { cn } from "utils/style.utils";

type Props = {
  show: boolean;
  children: ReactNode;
  isShownClass?: string;
  isHiddenClass?: string;
  delay?: number;
  className?: string;
  style?: CSSProperties;
};

const TransitionWrapper = forwardRef<HTMLDivElement, Props>(
  function TransitionWrapper(
    {
      show,
      children,
      className,
      delay = 0,
      isHiddenClass,
      isShownClass,
      style,
    },
    ref,
  ) {
    const [isHidden, setIsHidden] = useState<boolean>(!show);
    const [isShown, setIsShown] = useState<boolean>(show);

    useEffect(() => {
      if (!show) {
        setIsShown(false);
        setTimeout(() => {
          setIsHidden(true);
        }, 200 + delay);
      }
      if (show) {
        setIsHidden(false);
        setTimeout(() => {
          setIsShown(true);
        }, 200 + delay);
      }
    }, [show, delay]);

    return (
      !isHidden && (
        <div
          ref={ref}
          className={cn(
            "transition-all ease-out",
            isShown ? isShownClass : isHiddenClass,
            className,
          )}
          style={{
            transitionDuration: `${200 + delay}ms`,
            ...style,
          }}
        >
          {children}
        </div>
      )
    );
  },
);

export default memo(TransitionWrapper);
