import { Capacitor } from "@capacitor/core";
import { useKeyboard } from "@capacitor-community/keyboard-react";
import {
  IonIcon,
  IonSpinner,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { FormEvent, memo, RefObject, useContext } from "react";
import { useIntl } from "react-intl";

import { cn } from "utils/style.utils";

import { useEnterSubmit } from "hooks";
import { useUser } from "queries";
import { ChatbotUIContext } from "services/ai-chatbot";
import { Message } from "types/chat.types";

import { FooterButton } from "components/@common/FooterButton";
import { FormTextarea } from "components/@forms/FormTextarea";

import { ChatSendButton, ChatStopGenerating } from "./components";

type Props = {
  isDetail?: boolean;
  onSendMessage?: (message: string) => void;
  currentInput?: string;
  setCurrentInput: (message: string) => void;
  chatInputRef: RefObject<HTMLTextAreaElement>;
  handleSendMessage: (message: string, chatMessages: Array<Message>) => void;
  handleFocusChatInput: () => void;
  onStopMessage: () => void;
};

const ChatInput = ({
  isDetail,
  onSendMessage,
  currentInput = "",
  setCurrentInput,
  chatInputRef,
  handleSendMessage,
  handleFocusChatInput,
}: Props) => {
  const intl = useIntl();
  const platform = Capacitor.getPlatform();
  const { keyboard } = useKeyboard();

  const { data: user, isLoading: userIsLoading } = useUser();

  const { formRef, onKeyDown } = useEnterSubmit();

  const { chatMessages, isGenerating, isLoading } =
    useContext(ChatbotUIContext);

  useIonViewWillLeave(() => {
    keyboard.hide();
  });

  useIonViewDidEnter(() => {
    handleFocusChatInput();
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Blur focus on mobile
    if (Capacitor.isNativePlatform()) {
      (e.target as HTMLFormElement)["message"]?.blur();
    }
    handleSendMessage(currentInput, chatMessages);
    onSendMessage?.(currentInput);
  };

  return (
    <FooterButton
      color="primary"
      bgColor="secondary"
      toolbarClass="!p-0"
      showOnDesktop
      startSlot={
        isLoading ? (
          <div className="ml-6">
            <IonSpinner name="crescent" color="primary" />
          </div>
        ) : (
          <div className="ml-6">
            <IonIcon src="/assets/icons/ico-AI.svg" color="primary" />
          </div>
        )
      }
      endSlot={
        isGenerating ? (
          <ChatStopGenerating />
        ) : (
          <ChatSendButton
            currentInput={currentInput}
            onSend={() => formRef.current?.requestSubmit()}
          />
        )
      }
    >
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="flex h-fit w-full flex-1"
      >
        <FormTextarea
          ref={chatInputRef}
          value={currentInput}
          setValue={setCurrentInput}
          autoFocus
          disabled={isGenerating}
          className={cn(
            "w-full flex-1 resize-none border-none bg-transparent !px-0 text-primary placeholder-primary/50 outline-none placeholder:line-clamp-1 disabled:placeholder:text-primary/30",
            platform === "ios" && "!py-3",
            platform === "web" && "!py-1",
          )}
          onKeyDown={onKeyDown}
          placeholder={
            userIsLoading
              ? ""
              : intl.formatMessage(
                  {
                    id: isGenerating
                      ? "chatbot.generating"
                      : isDetail
                        ? "chatbot.ask.user"
                        : "chatbot.input.placeholder",
                  },
                  {
                    name:
                      user?.firstName ??
                      intl.formatMessage({ id: "common.stranger" }),
                  },
                )
          }
        />
      </form>
    </FooterButton>
  );
};

export default memo(ChatInput);
