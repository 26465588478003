import { IonIcon } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { cn } from "utils/style.utils";

import { useIsDesktop } from "hooks";

const Warning = () => {
  const isDesktop = useIsDesktop();
  return (
    <div
      className={cn(
        "flex flex-row gap-2 border-neutral bg-secondary text-neutral",
        isDesktop ? "border-none pt-2" : "border-y p-6",
      )}
    >
      <div className="flex flex-row items-center gap-2 text-sm">
        <IonIcon src={"/assets/icons/ico-AI.svg"} />
      </div>
      <div>
        <p className="text-sm font-medium uppercase leading-6">
          <FormattedMessage id="talk_detail.ai_content.warning" />
        </p>
      </div>
    </div>
  );
};

export default Warning;
