import { Emotions } from "types/reaction.types";

export const emotionsMap = {
  [Emotions.Hahaha]: "HAHAHA",
  [Emotions.Boom]: "BOOM",
  [Emotions.Crushin]: "CRUSHIN'",
  [Emotions.WaitWhat]: "WAIT, WHAT?",
  [Emotions.Zappp]: "ZAPPP",
  [Emotions.AhaMoment]: "AHA MOMENT",
  [Emotions.Eggplant]: "EGGPLANT",
};
