export const CHAT_SUGGESTIONS: { [key: string]: Array<string> } = {
  practicalInfo: ["Nice spots to chill in Antwerp"],
  insights: [
    "Key takeaways from [Speaker Name]'s talk?",
    "[Speaker Name]'s talk in a haiku?",
    "What was the buzzword of UBN24?",
  ],
  fun: ["Will AI take over our jobs?", "What’s Deep Clean?"],
  novemberFiveMagic: [
    "What makes a Memorable Experience (MX)?",
    "MX vibes in this app's design?",
    "What's MX all about?",
  ],
};
