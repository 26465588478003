import { DataResponse } from "types/common.types";
import { SpeakerBaseResponse, SpeakerResponse } from "types/speaker.types";

import RestApiService from "..";

export default {
  getSpeaker(this: RestApiService, id: string) {
    return this.api.get<DataResponse<SpeakerResponse>>(`/speakers/${id}`);
  },
  getSpeakers(this: RestApiService) {
    return this.api.get<DataResponse<Array<SpeakerBaseResponse>>>(`/speakers`);
  },
};
