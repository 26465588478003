import { DataResponse } from "types/common.types";
import {
  GetReactionsParams,
  GetReactionsResponse,
  PostKeyTakeawayReactionParams,
  PostReflectionReactionParams,
  Reaction,
} from "types/reaction.types";

import RestApiService from "..";

export default {
  getReactions(this: RestApiService, { type }: GetReactionsParams) {
    return this.api.get<DataResponse<Array<GetReactionsResponse>>>(
      `/users/me/${type}/reactions`,
    );
  },
  createReflectionReaction(
    this: RestApiService,
    { reflectionId, emotion }: PostReflectionReactionParams,
  ) {
    return this.api.post<DataResponse<Reaction>>(
      `/reflections/${reflectionId}/reactions`,
      {
        data: { emotion },
      },
    );
  },
  createKeyTakeawayReaction(
    this: RestApiService,
    { keyTakeawayId, speakerId, emotion }: PostKeyTakeawayReactionParams,
  ) {
    return this.api.post<DataResponse<Reaction>>(
      `/speakers/${speakerId}/key-takeaways/${keyTakeawayId}/reactions`,
      {
        data: { emotion },
      },
    );
  },
  deleteReflectionReaction(
    this: RestApiService,
    { emotion, reflectionId }: PostReflectionReactionParams,
  ) {
    return this.api.delete(`/reflections/${reflectionId}/reactions/${emotion}`);
  },
  deleteKeyTakeawayReaction(
    this: RestApiService,
    { emotion, keyTakeawayId, speakerId }: PostKeyTakeawayReactionParams,
  ) {
    return this.api.delete(
      `/speakers/${speakerId}/key-takeaways/${keyTakeawayId}/reactions/${emotion}`,
    );
  },
};
