import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import { ChatbotUIContext } from "services/ai-chatbot";
import { chatbotMessageSent } from "services/avo/avo";

import { useChatHandler } from "./useChatHandler";

type Props = {
  currentInput: string;
  setCurrentInput: (input: string) => void;
  onMessageWillSend?: () => void;
};

export const useChatbot = ({
  currentInput,
  setCurrentInput,
  onMessageWillSend,
}: Props) => {
  const { ref: scrollRef, entry } = useInView({ threshold: 0.2 });
  const {
    chatMessages,
    setChatMessages,
    isGenerating,
    isLoading,
    resetSession,
    abortController,
    firstTokenReceived,
  } = useContext(ChatbotUIContext);

  const { chatInputRef, handleSendMessage, handleFocusChatInput } =
    useChatHandler();
  const [isSuggestion, setIsSuggestion] = useState<boolean>(false);

  const useSuggestion = (suggestion: string) => {
    setCurrentInput(suggestion);
    handleFocusChatInput();
    setIsSuggestion(true);
  };
  const resetIsSuggested = () => {
    setIsSuggestion(false);
  };

  const clearChat = () => {
    setChatMessages([]);
    resetSession();
    handleFocusChatInput();
  };

  const stopMessage = () => {
    if (isGenerating && !isLoading) {
      abortController?.abort();
      setCurrentInput("");
    }
  };

  const scrollDown = () => {
    setTimeout(() => {
      entry?.target.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }, 100);
  };

  const sendMessage = () => {
    if (!currentInput.trim() || isGenerating) return;
    scrollDown();
    stopMessage();
    setCurrentInput("");
    chatInputRef.current?.setAttribute("style", "height: 20px");
    onMessageWillSend?.();
    handleSendMessage(currentInput, chatMessages);
    handleFocusChatInput();

    if (isSuggestion) {
      chatbotMessageSent({ messageType: "suggested" });
    } else {
      chatbotMessageSent({ messageType: "custom" });
    }

    resetIsSuggested();
  };

  useEffect(() => {
    if (currentInput.includes("[") && currentInput.includes("]")) {
      setTimeout(() => {
        const firstVariableIndexOpen = currentInput.indexOf("[");
        const firstVariableIndexClose = currentInput.indexOf("]") + 1;
        chatInputRef.current?.setSelectionRange(
          firstVariableIndexOpen,
          firstVariableIndexClose,
        );
      });
    }
  }, [currentInput, chatInputRef]);

  return {
    chatMessages,
    sendMessage,
    isGenerating,
    isLoading,
    firstTokenReceived,
    clearChat,
    stopMessage,
    useSuggestion,
    scrollRef,
    scrollDown,
    chatInputRef,
    focusChatInput: handleFocusChatInput,
    isSuggestion,
    resetIsSuggested,
  };
};
