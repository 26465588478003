import { useQuery } from "@tanstack/react-query";

import { SpeakerQueries } from "./queries";

export const useSpeaker = (
  speakerId: string,
  config?: ReturnType<typeof SpeakerQueries.getSpeakerById>,
) => {
  const { data, status, isLoading, error } = useQuery({
    ...SpeakerQueries.getSpeakerById(speakerId),
    ...config,
  });

  return {
    data,
    status,
    isLoading,
    error,
  };
};
