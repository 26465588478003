import { captureEvent } from "@sentry/react";
import { ReactNode } from "react";
import { IntlProvider } from "react-intl";

import { useIntlStore } from "store";

import { intl } from "./config";

interface Props {
  children: ReactNode;
}

const IntlSelectorProvider = ({ children }: Props) => {
  const locale = useIntlStore((state) => state.locale);

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={intl[locale].messages}
      onError={(error) => captureEvent(error)}
    >
      {children}
    </IntlProvider>
  );
};

export default IntlSelectorProvider;
