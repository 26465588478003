import { IonIcon, IonSkeletonText } from "@ionic/react";

const AIContentSkeleton = () => {
  return (
    <div className="flex w-full flex-row items-center gap-2 border-y border-neutral bg-secondary px-4 py-6">
      <IonIcon src={"/assets/icons/ico-AI.svg"} color="medium" />
      <IonSkeletonText
        animated={true}
        className="m-0 h-4 w-full bg-neutral-400 bg-opacity-10"
      />
    </div>
  );
};

export default AIContentSkeleton;
