export enum DiscoBallTileType {
  USER_CONTRIBUTION = "USER",
  PHOTO = "PHOTO",
  KEY_TAKEAWAY = "KEY_TAKEAWAY",
  SPEAKER = "SPEAKER",
}

export type DiscoBallTileResponse = {
  tileType: DiscoBallTileType;
  entityId: number | null;
  imageUrl: string;
  updatedAt: Date;
};
