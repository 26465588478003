import { ToastOptions, useIonToast } from "@ionic/react";

type Props = ToastOptions;

export const useToastNotification = ({
  message,
  duration = 3000,
  position = "bottom",
  animated = true,
  swipeGesture = "vertical",
  color = "medium",
  icon = undefined,
  buttons = [],
  layout = "baseline",
}: Props) => {
  const [present, dismiss] = useIonToast();

  const presentToast = () => {
    present({
      message,
      duration,
      position,
      animated,
      swipeGesture,
      color,
      icon,
      layout,
      cssClass: "toast-notification",
      buttons: [
        ...buttons,
        {
          icon: "/assets/icons/ico-close.svg",
          text: "",
          role: "cancel",
        },
      ],
    });
  };

  return { presentToast, dismissToast: dismiss };
};
