import { IonSkeletonText } from "@ionic/react";
import { forwardRef } from "react";

import { DateHHMMSS } from "types/common.types";

import StartHourItem from "./StarHourItem";

type Props = {
  startHour: DateHHMMSS;
  isLoading?: boolean;
};

const StartHour = forwardRef<HTMLDivElement, Props>(
  ({ startHour, isLoading }, ref) => {
    // Extracting the hour, minute, and day part from the startHour (e.g. 05:00:00 PM)
    const [hour, minute] = startHour.split(":");
    const [_, dayPart] = startHour.split(" ");

    return (
      <div
        ref={ref}
        className="sticky top-[51px] z-10 grid h-[120px] grid-cols-3 border-b border-neutral bg-primary md:top-0"
      >
        {isLoading &&
          Array.from({ length: 3 }).map((_, idx) => (
            <IonSkeletonText
              key={idx}
              animated
              className="h-[120px] flex-1 border-r border-neutral last:border-r-0"
            />
          ))}
        {!isLoading &&
          [hour, minute, dayPart].map((label, index) => (
            <StartHourItem label={label} key={index} />
          ))}
      </div>
    );
  },
);

StartHour.displayName = "StartHour";

export default StartHour;
