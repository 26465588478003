import { Capacitor } from "@capacitor/core";
import { useKeyboard } from "@capacitor-community/keyboard-react";
import { IonFooter, IonIcon, IonText, IonToolbar } from "@ionic/react";
import { memo, ReactNode } from "react";

import { cn } from "utils/style.utils";

type Props = {
  onFooterClick?: () => void;
  bgColor?: string;
  color?: string;
  startIcon?: string;
  endIcon?: string;
  onIconClick?: () => void;
  children?: ReactNode;
  toolbarClass?: string;
  preSlotToolbarClass?: string;
  textClass?: string;
  iconClickDisabled?: boolean;
  buttonType?: "submit" | "reset" | "button";
  buttonFormId?: string;
  preSlot?: ReactNode;
  footerRef?: React.RefObject<HTMLIonFooterElement>;
  startSlot?: ReactNode;
  endSlot?: ReactNode;
  showOnDesktop?: boolean;
};

const FooterButton = ({
  onFooterClick,
  bgColor = "primary",
  color = "dark",
  startIcon,
  endIcon,
  onIconClick,
  children,
  toolbarClass,
  preSlotToolbarClass,
  textClass,
  iconClickDisabled,
  buttonType = "button",
  buttonFormId,
  preSlot,
  footerRef,
  startSlot,
  endSlot,
  showOnDesktop = false,
}: Props) => {
  const platform = Capacitor.getPlatform();
  const keyboard = useKeyboard();
  return (
    <IonFooter
      ref={footerRef}
      className={cn("ion-no-border", !showOnDesktop && "md:hidden")}
    >
      {preSlot && (
        <div className={cn("unstyled", preSlotToolbarClass)}>{preSlot}</div>
      )}
      <IonToolbar
        color={bgColor}
        className={cn(
          "group border-t transition-all duration-200",
          platform === "ios" && keyboard.isOpen && "!pb-2 pt-2",
          platform === "ios" && !keyboard.isOpen && "pt-2 !pb-safe",
          platform === "web" && "!py-1",
          !iconClickDisabled && !!onFooterClick && "active:brightness-95",
          toolbarClass,
        )}
        style={{ borderColor: `var(--ion-color-${color})` }}
        onClick={onFooterClick}
      >
        {startSlot && <div slot="start">{startSlot}</div>}
        {startIcon && (
          <IonIcon
            slot="start"
            src={startIcon}
            color={color}
            className="ml-6"
          />
        )}
        <IonText
          color={color}
          className={cn(
            "flex h-full items-center pl-2 font-normal leading-[24px]",
            !startIcon && !startSlot && "ml-4",
            textClass,
          )}
        >
          {children}
        </IonText>

        {endSlot && <div slot="end">{endSlot}</div>}
        {endIcon && (
          <button
            onClick={onIconClick}
            slot="end"
            disabled={iconClickDisabled}
            className={cn(
              "mr-6 flex h-full items-center transition-all duration-200 active:translate-x-2 disabled:opacity-50 disabled:active:translate-x-0",
              !!onFooterClick && "group-active:translate-x-4",
            )}
            type={buttonType}
            form={buttonFormId}
          >
            <IonIcon src={endIcon} color={color} />
          </button>
        )}
      </IonToolbar>
    </IonFooter>
  );
};

export default memo(FooterButton);
