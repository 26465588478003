type Props = {
  label: string;
};

const StartHourItem = ({ label }: Props) => {
  return (
    <span className="flex h-[120px] flex-1 items-center justify-center border-r border-neutral pb-[6px] text-center text-3xl font-medium text-neutral last:border-r-0">
      {label}
    </span>
  );
};

export default StartHourItem;
