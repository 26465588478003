import {
  IonBackButton,
  IonButton,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { memo, ReactNode, useMemo } from "react";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useIsDesktop } from "hooks";

type Props = {
  children: ReactNode;
  color?: string;
  bgColor?: string;
  endSlot?: ReactNode;
  onBackClick?: () => void;
  postSlot?: ReactNode;
  defaultBack?: string;
  className?: string;
};

const TitleHeader = ({
  children,
  color = "primary",
  bgColor = "light",
  endSlot,
  postSlot,
  onBackClick,
  defaultBack = generatePath(Routes.Home),
  className,
}: Props) => {
  const isDesktop = useIsDesktop();
  const backIcon = useMemo(
    () =>
      isDesktop
        ? "/assets/icons/ico-close.svg"
        : "/assets/icons/ico-arrow-left.svg",
    [isDesktop],
  );
  return (
    <IonHeader mode="ios" translucent color={bgColor} className={className}>
      <IonToolbar
        color={bgColor}
        style={{
          "--border-width": "0 0 1px 0",
          "--border-color": `var(--ion-color-${color})`,
        }}
        className="!pb-2 !pt-safe-or-2"
      >
        <div slot="start">
          {onBackClick ? (
            <IonButton
              onClick={onBackClick}
              color={color}
              fill="clear"
              className="m-0 !aspect-square -translate-x-0.5 md:ml-2"
            >
              <IonIcon src={backIcon} slot="icon-only" />
            </IonButton>
          ) : (
            <IonBackButton
              icon={backIcon}
              defaultHref={defaultBack}
              mode="ios"
              type="button"
              color={color}
              text=""
              className="!aspect-square md:ml-2"
            />
          )}
        </div>
        <IonTitle
          className="text-base font-black uppercase italic"
          color={color}
        >
          {children}
        </IonTitle>
        {endSlot && (
          <div slot="end" className="pr-4">
            {endSlot}
          </div>
        )}
      </IonToolbar>
      {postSlot}
    </IonHeader>
  );
};

export default memo(TitleHeader);
