import { Capacitor } from "@capacitor/core";
import { IonRouterLink } from "@ionic/react";
import { lazy, Suspense } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { cn } from "utils/style.utils";

import { ENABLE_LOGIN } from "constants/app.constants";
import { Routes } from "constants/routes.constants";
import { onboardingStart } from "services/avo/avo";
import { User } from "types/user.types";

type Props = {
  user?: User;
  userIsLoading?: boolean;
};

const TextSlider = lazy(
  () => import("components/@transitions/TextSlider/TextSlider"),
);
const HomeBottomLinks = ({ user, userIsLoading }: Props) => {
  const platform = Capacitor.getPlatform();

  return (
    <div
      className={cn(
        "fixed inset-x-0 bottom-0 z-10 flex w-full flex-row items-end justify-between uppercase transition-all duration-200 !mb-safe-or-10 md:hidden",
        ENABLE_LOGIN && userIsLoading ? "opacity-0" : "opacity-100",
        platform === "ios" && "!pb-11",
        platform === "web" && "!pb-6",
        platform === "android" && "!pb-4",
      )}
    >
      <IonRouterLink
        routerLink={generatePath(Routes.N5Page)}
        routerDirection="forward"
        color="light"
        className="whitespace-pre-line px-4 py-6 uppercase underline"
      >
        <FormattedMessage id="home.mx_tm.title" />
      </IonRouterLink>

      {ENABLE_LOGIN && (
        <>
          {!!user ? (
            <IonRouterLink
              routerLink={generatePath(Routes.User, {
                userId: user?.id || "",
              })}
              routerDirection="forward"
              color="light"
              className="px-4 py-6 uppercase underline"
            >
              <FormattedMessage id="reflections.title" />
            </IonRouterLink>
          ) : (
            <IonRouterLink
              routerLink={generatePath(Routes.Onboarding)}
              routerDirection="forward"
              color="light"
              className="whitespace-pre-line px-4 py-6 text-end uppercase underline"
              onClick={() => onboardingStart()}
            >
              <Suspense
                fallback={<FormattedMessage id="home.claim_tile.title" />}
              >
                <TextSlider
                  isPlaying={!user}
                  className="h-[52px] w-40"
                  textClass="underline uppercase"
                  firstText={<FormattedMessage id="home.claim_tile.title" />}
                  secondText={
                    <FormattedMessage id="home.claim_tile.share_vibe" />
                  }
                />
              </Suspense>
            </IonRouterLink>
          )}
        </>
      )}
    </div>
  );
};

export default HomeBottomLinks;
