import { useMutation } from "@tanstack/react-query";

import { captureError } from "utils/sentry.utils";

import { authApiService } from "services";
import { SendMessagePayload } from "types/chat.types";

export const useSendChatMessage = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (payload: SendMessagePayload) => {
      const { body } = await authApiService.sendChatMessage(payload);
      return body as ReadableStream<Uint8Array>;
    },
    onError: (error) => captureError("Error sending chat message", error),
  });

  return {
    sendChatMessage: mutateAsync,
    isPending,
  };
};
