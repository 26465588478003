import { IonButton, IonContent, IonIcon, IonPopover } from "@ionic/react";
import { FormEvent, memo, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { adjustShadowRootStyles, cn } from "utils/style.utils";

import { useChatbot, useEnterSubmit } from "hooks";
import { useUser } from "queries";

import { FormTextarea } from "components/@forms/FormTextarea";

import {
  ChatClearButton,
  ChatMessages,
  ChatSendButton,
  ChatStopGenerating,
} from "./components";

const ChatPopper = () => {
  const intl = useIntl();
  const popoverRef = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: unknown) => {
    if (popoverRef.current) {
      setPopoverOpen(true);
      popoverRef.current.event = e;
    }
  };
  const closePopover = () => setPopoverOpen(false);

  const { data: user, isLoading: userIsLoading } = useUser();

  const [currentInput, setCurrentInput] = useState<string>("");
  const { formRef, onKeyDown } = useEnterSubmit();

  const {
    chatMessages,
    sendMessage,
    isGenerating,
    chatInputRef,
    clearChat,
    useSuggestion,
    scrollRef,
  } = useChatbot({ currentInput, setCurrentInput });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage();
  };

  useEffect(() => {
    adjustShadowRootStyles(
      [".popover-desktop", ".popover-wrapper .popover-content"],
      "border-radius: 0 !important; border: 1px solid red; !important",
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", closePopover);
    return () => window.removeEventListener("resize", closePopover);
  });

  return (
    <>
      <div className="fixed bottom-8 right-8 flex h-fit w-fit flex-col items-end gap-2.5 p-0">
        {/* This is needed so that the popover positioning is always based on the parent element */}
        <button
          className="absolute inset-0 z-10 h-full w-full"
          onClick={openPopover}
        />
        <div
          className={cn(
            "flex h-full w-fit items-center justify-center gap-2 border border-black bg-secondary py-4 pl-5 pr-6 transition-all duration-200",
            popoverOpen ? "opacity-0" : "opacity-100",
          )}
        >
          <img src="/assets/icons/ico-AI.svg" />
          <p className="text-[16px] font-normal">
            <FormattedMessage id="chatbot.ask.stranger" />
          </p>
        </div>
        <div
          className={cn(
            "h-12 w-12 p-0",
            popoverOpen ? "bg-black" : "bg-secondary",
          )}
        >
          <IonIcon
            src="/assets/icons/ico-UBN.svg"
            color={popoverOpen ? "secondary" : "black"}
            style={{
              borderColor: popoverOpen ? "var(--ion-color-secondary)" : "black",
              backgroundColor: popoverOpen
                ? "black"
                : "var(--ion-color-secondary)",
            }}
            className="h-full w-full border transition-all duration-200 ease-out"
          />
        </div>
      </div>

      <IonPopover
        ref={popoverRef}
        isOpen={popoverOpen}
        onDidDismiss={closePopover}
        side="top"
        alignment="end"
        size="auto"
        style={{
          "--min-height": "70svh",
          "--max-height": "90svh",
          "--max-width": "55svw",
          "--width": "50svw",
          "--min-width": "40svw",
          "--background": "--ion-color-secondary",
          "--offset-y": "56px",
          "--backdrop-opacity": "0",
        }}
      >
        <IonContent
          color="secondary"
          className="flex h-full min-h-[70svh] w-full min-w-[40svw] flex-col rounded-none"
        >
          <div className="fixed top-0 z-10 flex h-[60px] w-full items-center justify-between border-b border-primary bg-secondary">
            <div className="flex w-28 items-center justify-start">
              <IonButton
                onClick={closePopover}
                color="primary"
                fill="clear"
                className="!aspect-square"
              >
                <IonIcon src="/assets/icons/ico-close.svg" slot="icon-only" />
              </IonButton>
            </div>
            <h2 className="pb02 pb-2 text-base font-black uppercase italic leading-none text-primary">
              <FormattedMessage id="chatbot.ask.title" />
            </h2>
            <div className="flex w-28 items-center justify-end pr-6">
              {!!chatMessages.length && (
                <ChatClearButton onClearChat={clearChat} />
              )}
            </div>
          </div>

          <section
            style={{ height: "calc(70svh - 120px)" }}
            className="fixed bottom-16 top-[60px] z-0 flex h-full w-full flex-col !justify-end overflow-scroll bg-secondary"
          >
            <ChatMessages
              scrollRef={scrollRef}
              useSuggestion={useSuggestion}
              user={user}
              userIsLoading={userIsLoading}
            />
          </section>

          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="fixed bottom-0 flex h-fit min-h-16 w-full items-center justify-between border-t border-primary bg-secondary"
          >
            <div className="ml-6 flex w-full items-center gap-2">
              <IonIcon src="/assets/icons/ico-AI.svg" color="primary" />
              <FormTextarea
                ref={chatInputRef}
                value={currentInput}
                setValue={setCurrentInput}
                wrapClass="py-4 bg-secondary"
                className={cn(
                  "resize-none text-[16px] text-primary caret-primary",
                  "placeholder-primary/50 placeholder:line-clamp-1 disabled:placeholder:text-primary/30",
                )}
                disabled={isGenerating}
                autoFocus
                onKeyDown={onKeyDown}
                placeholder={intl.formatMessage({
                  id: isGenerating
                    ? "chatbot.generating"
                    : "chatbot.input.placeholder",
                })}
              />
            </div>
            {isGenerating ? (
              <ChatStopGenerating />
            ) : (
              <ChatSendButton
                currentInput={currentInput}
                onSend={sendMessage}
              />
            )}
          </form>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default memo(ChatPopper);
