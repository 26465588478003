import { IonSkeletonText, IonText, useIonRouter } from "@ionic/react";
import { Children, isValidElement, memo, ReactElement } from "react";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import MarkdownMessageMemo from "./MarkdownMessageMemo";

interface Props {
  content: string;
}

const MarkdownMessage = ({ content }: Props) => {
  const router = useIonRouter();

  const titleStyle =
    "mt-4 !text-xl !font-medium !uppercase !not-italic !leading-[32.2px]";

  return (
    <MarkdownMessageMemo
      remarkPlugins={[remarkGfm, remarkMath]}
      components={{
        a: ({ children, ...props }) => (
          <a
            {...props}
            className="font-medium underline"
            onClick={(e) => {
              // use ionic-router to navigate if the link is internal
              const url = new URL(props.href || "");
              if (url.hostname !== window.location.hostname) return;
              e.preventDefault();
              router.push(url.pathname, "forward");
            }}
          >
            {children}
          </a>
        ),
        ol: ({ children }) => <ol className="list-decimal">{children}</ol>,
        li: ({ children }) => (
          <li className="my-4 list-inside !leading-[24px]">{children}</li>
        ),
        p: ({ children }) => (
          <IonText className="mb-1 last-of-type:mb-0">{children}</IonText>
        ),
        h1: ({ children }) => <h1 className={titleStyle}>{children}</h1>,
        h2: ({ children }) => <h2 className={titleStyle}>{children}</h2>,
        h3: ({ children }) => <h3 className={titleStyle}>{children}</h3>,
        h4: ({ children }) => <h4 className={titleStyle}>{children}</h4>,
        h5: ({ children }) => <h5 className={titleStyle}>{children}</h5>,
        h6: ({ children }) => <h6 className={titleStyle}>{children}</h6>,
        img: ({ node, ...props }) => <img {...props} />,
        code: ({ node, className, children, ...props }) => {
          const childArray = Children.toArray(children);
          const firstChild = childArray[0] as React.ReactElement;
          const firstChildAsString = isValidElement(firstChild)
            ? (firstChild as ReactElement).props.children
            : firstChild;

          if (firstChildAsString === "▍") {
            return <IonSkeletonText animated className="h-4 flex-1" />;
          }

          if (typeof firstChildAsString === "string") {
            childArray[0] = firstChildAsString.replace("`▍`", "▍");
          }

          <code {...props}>{childArray}</code>;
        },
      }}
    >
      {content}
    </MarkdownMessageMemo>
  );
};

export default memo(MarkdownMessage);
