import { useThree } from "@react-three/fiber";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

// Enums for Benchmark and Texture tiers
export enum BenchmarkTier {
  Unknown = -1,
  Low = 1,
  Medium = 2,
  High = 3,
}

enum TextureTier {
  High = 8.5,
  Medium = 6.5,
  Low = 1.5,
}

// Calculate texture score for GPU
function calculateTextureScore(gl: WebGLRenderingContext): number {
  const maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
  const maxCombinedTextureUnits = gl.getParameter(
    gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS,
  );

  const score =
    (maxTextureSize * maxTextureSize * maxCombinedTextureUnits) / 1000000000;
  return score;
}

// Create the context
const BenchmarkContext = createContext<BenchmarkTier>(BenchmarkTier.Unknown);

// Provider component
export const BenchmarkProvider = ({ children }: { children: ReactNode }) => {
  const { gl } = useThree();
  const [tier, setTier] = useState<BenchmarkTier>(BenchmarkTier.Unknown);

  useEffect(() => {
    if (tier === BenchmarkTier.Unknown) {
      const getTier = async () => {
        const context = gl.getContext();
        const textureScore = calculateTextureScore(context);

        const { getGPUTier } = await import("detect-gpu");

        let gpuTier = -1;
        try {
          const gpu = await getGPUTier();
          if (gpu.type === "BENCHMARK") {
            gpuTier = gpu.tier;
          }
        } finally {
          if (gpuTier >= 3) {
            setTier(BenchmarkTier.High);
          } else if (gpuTier === 2) {
            setTier(BenchmarkTier.Medium);
          } else {
            if (textureScore > TextureTier.High) {
              setTier(BenchmarkTier.High);
            } else if (textureScore > TextureTier.Medium) {
              setTier(BenchmarkTier.Medium);
            } else {
              setTier(BenchmarkTier.Low);
            }
          }
        }
      };
      getTier();
    }
  }, [gl, tier]);

  return (
    <BenchmarkContext.Provider value={tier}>
      {children}
    </BenchmarkContext.Provider>
  );
};

// Custom hook to use the benchmark tier
export const useBenchmarkTier = () => {
  const context = useContext(BenchmarkContext);
  if (context === undefined) {
    throw new Error("useBenchmarkTier must be used within a BenchmarkProvider");
  }
  return context;
};
