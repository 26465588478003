import { IonRouterOutlet } from "@ionic/react";
import { useEffect } from "react";
import { Redirect, Route } from "react-router";

import { Routes } from "constants/routes.constants";
import { usePosthogIdentify } from "hooks";
import { usePrefetch } from "hooks/usePrefetch";
import { appInstall } from "services/avo/avo";

import Booth from "pages/booth/Booth";
import Chat from "pages/chat/Chat";
import Home from "pages/home/Home";
import N5Page from "pages/n5-page/N5Page";
import Schedule from "pages/schedule/Schedule";
import Talk from "pages/talk/Talk";
// import Community from "pages/community/Community";
// import EditProfile from "pages/edit-profile/EditProfile";
// import Onboarding from "pages/onboarding/Onboarding";
// import ShareReflection from "pages/share-reflection/ShareReflection";
// import User from "pages/user/User";

const AppRoutes = () => {
  usePosthogIdentify();

  useEffect(() => {
    if (!localStorage.getItem("appInstallIsTracked")) {
      appInstall({ installSource: "Unknown" });
      localStorage.setItem("appInstallIsTracked", "true");
    }
  }, []);

  usePrefetch();

  // Commented routes are temporarily disabled
  return (
    <IonRouterOutlet>
      <Route path={Routes.Home} render={() => <Home />} />
      <Route path={Routes.Schedule} render={() => <Schedule />} />
      <Route path={Routes.Talk} render={() => <Talk />} />
      <Route path={Routes.Chat} render={() => <Chat />} />
      <Route path={Routes.N5Page} render={() => <N5Page />} />
      <Route path={Routes.Booth} render={() => <Booth />} />
      {/* <Route path={Routes.Onboarding} render={() => <Onboarding />} /> */}
      {/* <Route path={Routes.User} render={() => <User />} /> */}
      {/* <Route path={Routes.EditProfile} render={() => <EditProfile />} /> */}
      {/* <Route path={Routes.Community} render={() => <Community />} /> */}
      {/* <Route path={Routes.ShareReflection} render={() => <ShareReflection />} /> */}
      <Redirect exact from="/" to={Routes.Home} />
      {/* Fallback/error route */}
      {/* <Redirect to={Routes.Home} /> */}
    </IonRouterOutlet>
  );
};

export default AppRoutes;
