import { IonSkeletonText } from "@ionic/react";
import { memo } from "react";

import { Image } from "components/@common/Image";

type Props = {
  imgSrc?: string;
  isLoading?: boolean;
};

const SpeakerImage = ({ imgSrc, isLoading }: Props) => {
  if (isLoading) {
    return (
      <div className="min-h-[180px] w-1/3">
        <IonSkeletonText
          animated
          className="h-full w-full bg-red-900 bg-opacity-10"
        />
      </div>
    );
  }

  if (imgSrc) {
    return (
      <div className="min-h-[180px] w-1/3 border-r border-neutral bg-neutral-50">
        <Image src={imgSrc} alt="Speaker image" />
      </div>
    );
  }

  return (
    <div className="min-h-[180px] w-1/3">
      <div className="relative h-full overflow-hidden border-r border-white">
        <div
          className="absolute -right-5 flex h-[150%] w-[150%] -skew-x-[9.71deg] items-center justify-center"
          style={{
            backgroundImage: `repeating-linear-gradient(to left, #e63223, #e63223 75%, #e63223 75.3%, #ffffff 100%)`,
            backgroundSize: "9px 9px",
            backgroundColor: "transparent",
          }}
        ></div>
      </div>
    </div>
  );
};

export default memo(SpeakerImage);
