import { forwardRef, TextareaHTMLAttributes, useEffect } from "react";

import { cn } from "utils/style.utils";

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  wrapClass?: string;
  value?: string;
  setValue?: (value: string) => void;
};

const FormTextarea = forwardRef<HTMLTextAreaElement, Props>(
  function FormTextarea(
    { value, setValue, id = "textarea-grow", className, wrapClass, ...props },
    ref,
  ) {
    useEffect(() => {
      const textareaEl = document.getElementById(id) as HTMLTextAreaElement;

      const autosize = () => {
        setTimeout(function () {
          textareaEl.style.cssText = "height:auto; padding:0";
          // for box-sizing other than "content-box" use:
          // el.style.cssText = '-moz-box-sizing:content-box';
          textareaEl.style.cssText = "height:" + textareaEl.scrollHeight + "px";
        }, 0);
      };

      textareaEl.addEventListener("keydown", autosize);
      return () => {
        textareaEl.removeEventListener("keydown", autosize);
      };
    });

    return (
      <div className={cn("w-full", wrapClass)}>
        <textarea
          ref={ref}
          id={id}
          {...props}
          rows={1}
          className={cn(
            "max-h-52 min-h-5 w-full resize-y overflow-auto bg-transparent focus:outline-none",
            className,
          )}
          value={value}
          onChange={(e) => setValue && setValue(e.target.value)}
        ></textarea>
      </div>
    );
  },
);

export default FormTextarea;
