import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";

import { apiService } from "services";
import { StaleTime } from "services/react-query/types";
import { DateString } from "types/common.types";
import { GetReflectionsParams } from "types/community.types";

export const REFLECTION_QUERY_PREFIX = "reflection-query";

const fetchReflections = async (params: GetReflectionsParams) => {
  const { data: reflections } = await apiService.getReflections(params);
  return reflections;
};

const fetchNewReflections = async (lastChecked: DateString) => {
  const { data: response } = await apiService.getNewReflections(lastChecked);
  return response.data || [];
};

export const ReflectionQueries = {
  getReflections: ({ page_size, userId = undefined }: GetReflectionsParams) =>
    infiniteQueryOptions({
      queryKey: [REFLECTION_QUERY_PREFIX, userId ?? "all"],
      queryFn: ({ pageParam = 1 }) =>
        fetchReflections({ page: pageParam, page_size, userId }),
      initialPageParam: 1,
      staleTime: StaleTime.FIFTEEN_MIN,
      getNextPageParam: (lastPage) => {
        const currentPage = lastPage._links.self || 1;
        const totalPages = lastPage._links.last || 1;
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      select: (data) => {
        const allData = data.pages.flatMap((page) => page.data);
        const lastPageMeta = data.pages[data.pages.length - 1]._meta;
        const lastPageLinks = data.pages[data.pages.length - 1]._links;

        const queryResponse = {
          data: allData,
          meta: lastPageMeta,
          links: lastPageLinks,
        };

        return queryResponse;
      },
    }),

  getNewReflections: (lastChecked: DateString) =>
    queryOptions({
      queryKey: [REFLECTION_QUERY_PREFIX, lastChecked],
      queryFn: () => fetchNewReflections(lastChecked),
      staleTime: StaleTime.MINUTE,
    }),
};
