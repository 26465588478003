import { IonText } from "@ionic/react";
import { useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";

import { CHAT_SUGGESTIONS } from "constants/chat-suggestions.constants";
import { useIsDesktop } from "hooks";

import { ScrollLeftRight } from "components/@common/ScrollLeftRight";

type Props = {
  onUseSuggestion: (suggestion: string) => void;
};

const ChatSuggestions = ({ onUseSuggestion }: Props) => {
  const isDesktop = useIsDesktop();
  // Take one random suggestion from each category
  const randomizedSuggestions = useMemo(
    () =>
      Object.values(CHAT_SUGGESTIONS).map(
        (suggestions) =>
          suggestions[Math.floor(Math.random() * suggestions.length)],
      ),
    [],
  );

  const scrollerRef = useRef<HTMLDivElement>(null);
  return (
    <div className="flex flex-col gap-2 py-4">
      <div className="flex items-center justify-between px-4">
        <IonText color="primary" className="text-sm font-medium uppercase">
          <FormattedMessage id="chatbot.suggestions" />
        </IonText>
        {isDesktop && <ScrollLeftRight scrollerRef={scrollerRef} />}
      </div>
      <div
        ref={scrollerRef}
        className="hide-scrollbar flex gap-2 overflow-x-scroll scroll-smooth px-4"
      >
        {randomizedSuggestions.map((suggestion, index) => (
          <div
            key={index}
            role="button"
            tabIndex={0}
            onClick={() => onUseSuggestion(suggestion)}
            className="max-h-36 w-fit min-w-40 max-w-56 border border-primary bg-secondary px-4 pb-4 pt-2 text-start text-base text-primary transition-all duration-100 active:brightness-95"
          >
            {suggestion}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatSuggestions;
