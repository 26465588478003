import { IonSkeletonText } from "@ionic/react";

const MediaSkeleton = () => {
  return (
    <div>
      <IonSkeletonText
        animated={true}
        className="m-0 h-[336px] w-full min-w-[48] bg-neutral-400 bg-opacity-10"
      />
    </div>
  );
};

export default MediaSkeleton;
