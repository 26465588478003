"use client";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

import { useSessionStorage } from "hooks";
import { useChatById } from "queries";
import { Message } from "types/chat.types";

import { ChatbotUIContext } from "./ChatContext";

interface Props {
  children: React.ReactNode;
}

const ChatProvider = ({ children }: Props) => {
  const [sessionId, setSessionId] = useSessionStorage("chat-id", nanoid());
  const [userInput, setUserInput] = useState<string>("");
  const [chatMessages, setChatMessages] = useState<Array<Message>>([]);
  const { data: initialMessages, isLoading } = useChatById(sessionId);

  useEffect(() => {
    if (initialMessages) {
      const messages = initialMessages.reverse();
      setChatMessages(messages);
    }
  }, [initialMessages]);

  // ACTIVE CHAT STORE
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [firstTokenReceived, setFirstTokenReceived] = useState<boolean>(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const resetSession = () => {
    setSessionId(nanoid());
    setChatMessages([]);
    setFirstTokenReceived(false);
    setIsGenerating(false);
    abortController?.abort();
  };

  return (
    <ChatbotUIContext.Provider
      value={{
        isLoading,
        sessionId,
        resetSession,
        userInput,
        setUserInput,
        chatMessages,
        setChatMessages,
        isGenerating,
        setIsGenerating,
        firstTokenReceived,
        setFirstTokenReceived,
        abortController,
        setAbortController,
      }}
    >
      {children}
    </ChatbotUIContext.Provider>
  );
};

export default ChatProvider;
