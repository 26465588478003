import { FormattedMessage } from "react-intl";

const InsightsAvailable = () => {
  return (
    <div className="mb-2 w-fit bg-secondary pb-[5px] pl-2 pr-[7px] pt-[3px] text-primary">
      <h6 className="font-medium">
        <FormattedMessage id="schedule.insights_available.title" />
      </h6>
    </div>
  );
};

export default InsightsAvailable;
