import { Suspense, SuspenseProps, useLayoutEffect, useState } from "react";

interface Props extends SuspenseProps {
  delay?: number;
}

const DelayedSuspense = ({ children, fallback, delay = 0 }: Props) => {
  const [fakeLoading, setFakeLoading] = useState(true);

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      setFakeLoading(false);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  return (
    <Suspense fallback={fallback}>
      {fakeLoading ? <FunkyLoader /> : children}
    </Suspense>
  );
};

const FunkyLoader = () => {
  throw new Promise(() => {});
};

export default DelayedSuspense;
