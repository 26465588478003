import * as Sentry from "@sentry/capacitor";

import config from "config";

import { isProd } from "utils/env.utils";

export const initNativeSentry = () => {
  Sentry.init({
    ...config.SENTRY,
    sampleRate: isProd() ? 0.1 : 1,
    dist: config.VERSION,
  });
};
