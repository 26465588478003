import {
  GetReactionsResponse,
  PostReactionParams,
  ReactionTypes,
} from "types/reaction.types";

export const updateReactionsCacheAdd = (
  type: ReactionTypes,
  vars: PostReactionParams,
  prevData?: Array<GetReactionsResponse>,
) => {
  // If there is no previous data, return a new array with the new reaction
  if (!prevData) {
    return type === ReactionTypes.Reflection
      ? [
          {
            reflectionId: vars.reflectionId,
            emotions: [vars.emotion],
          },
        ]
      : [
          {
            keyTakeawayId: vars.keyTakeawayId,
            speakerId: vars.speakerId,
            emotions: [vars.emotion],
          },
        ];
  }

  // If the reaction is not already in the cache, add it
  if (
    type === ReactionTypes.Reflection &&
    !prevData.find((item) => item.reflectionId === vars.reflectionId)
  ) {
    return [
      ...prevData,
      {
        reflectionId: vars.reflectionId,
        emotions: [vars.emotion],
      },
    ];
  } else if (
    type === ReactionTypes.KeyTakeaway &&
    !prevData.find((item) => item.keyTakeawayId === vars.keyTakeawayId)
  ) {
    return [
      ...prevData,
      {
        keyTakeawayId: vars.keyTakeawayId,
        speakerId: vars.speakerId,
        emotions: [vars.emotion],
      },
    ];
  }
  // If the reaction is already in the cache, update it
  return prevData.map((item) => {
    if (
      !item.emotions.includes(vars.emotion) &&
      ((!!item.reflectionId && item.reflectionId === vars.reflectionId) ||
        (!!item.keyTakeawayId && item.keyTakeawayId === vars.keyTakeawayId))
    ) {
      return {
        ...item,
        emotions: [...item.emotions, vars.emotion],
      };
    }
    return item;
  });
};

export const updateReactionsCacheRemove = (
  vars: PostReactionParams,
  prevData?: Array<GetReactionsResponse>,
) => {
  if (!prevData) return [];

  return prevData.map((item) => {
    if (
      (!!item.reflectionId && item.reflectionId === vars.reflectionId) ||
      (!!item.keyTakeawayId && item.keyTakeawayId === vars.keyTakeawayId)
    ) {
      return {
        ...item,
        emotions: item.emotions.filter((emotion) => emotion !== vars.emotion),
      };
    }
    return item;
  });
};
