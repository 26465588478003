/* eslint-disable react/no-unknown-property */
const Lights = () => {
  return (
    <>
      <ambientLight intensity={6} color={[1, 0.9, 1]} />
      <pointLight position={[4, -3, 5]} color="#E63222" />
      <pointLight position={[-5, 5, 5]} color="#D2B4FF" />
      <pointLight position={[3, 2, 4]} color="#D2B4FF" />
      <pointLight position={[-4, 4, 3]} color="#D2B4FF" />
      <pointLight position={[-3, -4, 5]} color="#E63222" />
    </>
  );
};

export default Lights;
