import config from "config";

import RestApiService from "./api";
import { createManager } from "./api/axios";

// api service for authenticated requests
export const authApiService = new RestApiService({
  api: createManager({ requestConfig: { baseURL: config.API_BASE } }),
});

// api service for non authenticated requests (we don't want to send auth headers because of caching)
export const apiService = new RestApiService({
  api: createManager({
    requestConfig: { baseURL: config.API_BASE },
    enableAuthHeader: false,
  }),
});
