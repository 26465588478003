import "./index.css";

import { Capacitor } from "@capacitor/core";
import React from "react";
import ReactDOM from "react-dom/client";

import { runInProd, runInStaging } from "utils/env.utils";

import { initNativeSentry } from "services/sentry/native-sentry";
import { initWebSentry } from "services/sentry/web-sentry";

import App from "./App";

runInProd(() => {
  if (Capacitor.isNativePlatform()) {
    initNativeSentry();
  } else {
    initWebSentry();
  }
});

runInStaging(() => {
  console.info("Running Sentry in staging mode for now");
  if (Capacitor.isNativePlatform()) {
    initNativeSentry();
  } else {
    initWebSentry();
  }
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
