import { IonText } from "@ionic/react";
import { RefObject, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { ChatbotUIContext } from "services/ai-chatbot";
import { User } from "types/user.types";

import { TransitionFade } from "components/@transitions";

import ChatList from "./ChatList";
import ChatSuggestions from "./ChatSuggestions";

type Props = {
  userIsLoading: boolean;
  user?: User;
  scrollRef: (node?: Element | null | undefined) => void;
  suggestionsRef?: RefObject<HTMLDivElement>;
  useSuggestion: (suggestion: string) => void;
  titleHeight?: number;
};

const ChatMessages = ({
  titleHeight,
  userIsLoading,
  user,
  scrollRef,
  suggestionsRef,
  useSuggestion,
}: Props) => {
  const { chatMessages } = useContext(ChatbotUIContext);
  return (
    <>
      <TransitionFade
        show={!chatMessages.length && !userIsLoading}
        className="absolute top-0 z-0 flex flex-col gap-2 overflow-y-auto p-4"
        style={titleHeight ? { height: `${titleHeight}px` } : {}}
      >
        <IonText
          color="primary"
          className="h-fit break-all text-lg font-black uppercase italic"
        >
          <FormattedMessage id="common.hi" />
          &nbsp;
          {user?.firstName ?? <FormattedMessage id="common.stranger" />}
        </IonText>

        <IonText color="primary" className="text-base leading-6">
          <FormattedMessage id="chatbot.disclaimer" />
        </IonText>
      </TransitionFade>

      <TransitionFade
        show={!!chatMessages.length}
        className="flex h-fit flex-col-reverse overflow-auto"
      >
        <ChatList ref={scrollRef} />
      </TransitionFade>

      <TransitionFade
        ref={suggestionsRef}
        show={!chatMessages.length}
        className="absolute bottom-0 left-0 right-0 z-20 flex h-fit flex-col-reverse overflow-auto !bg-secondary"
      >
        <ChatSuggestions onUseSuggestion={useSuggestion} />
      </TransitionFade>
    </>
  );
};

export default ChatMessages;
