import { Style } from "@capacitor/status-bar";
import {
  IonContent,
  IonPage,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import throttle from "lodash.throttle";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useParams } from "react-router-dom";

import { isDateInPast, titleToSlug } from "utils/common.utils";
import { QueryParam } from "utils/location.utils";

import { Routes } from "constants/routes.constants";
import {
  useCheckLoginAction,
  useColorSafeAreas,
  useIsDesktop,
  useShowHeaderTitleOnScroll,
} from "hooks";
import { useSpeaker, useUser } from "queries";
import { SpeakerQueries } from "queries/speaker/queries";
import { talkDetailView } from "services/avo/avo";
import { SpeakerResponse } from "types/speaker.types";

import { FooterButton } from "components/@common/FooterButton";
import { TitleHeader } from "components/@common/TitleHeader";
import { About } from "components/@talk/About";
import { AIContent } from "components/@talk/AIContent";
import { Intro } from "components/@talk/Intro";
import { Media } from "components/@talk/Media";
import { TransitionFly } from "components/@transitions";

const getTrackEventTalkState = (data: SpeakerResponse) => {
  if (data.hasAIReflections) return "content_added";
  if (isDateInPast(data.endDatetime)) return "ended";
  if (isDateInPast(data.startDatetime)) return "live";
  return "upcoming";
};

const Talk = () => {
  const { talkId } = useParams<{ talkId: string }>();
  const { data, isLoading } = useSpeaker(talkId, {
    queryKey: SpeakerQueries.getSpeakerById(talkId).queryKey,
    refetchInterval: 10000,
  });
  const { data: user } = useUser();
  const hasTalkEnded = !!data
    ? isDateInPast(data.endDatetime) || data.hasAIReflections
    : false;

  useColorSafeAreas({
    currentRoute: "/talk",
    statusBar: "#ffffff",
    navigationBar: hasTalkEnded ? "#e63223" : "#ffffff",
    iconsColor: Style.Light,
  });
  const router = useIonRouter();
  const isDesktop = useIsDesktop();

  const aboutSectionRef = useRef<null | HTMLDivElement>(null);

  const [loadedAssets, setLoadedAssets] = useState(0);

  const introRef = useRef<HTMLDivElement>(null);
  const { titleIsShown, handleHeaderScroll } = useShowHeaderTitleOnScroll(
    introRef.current ? introRef.current.clientHeight / 2 : 200,
  );

  const handleAddReflection = useCheckLoginAction({
    loginAction: () =>
      router.push(
        `${generatePath(Routes.ShareReflection, { queryString: "asd" })}/?${QueryParam.TalkId}=${talkId}&${QueryParam.TalkSlug}=${titleToSlug(data?.name || "")}`,
        "forward",
      ),
    errorMessage: "community.reflections.post.not_logged_in",
    user,
  });

  useIonViewDidEnter(() => {
    if (data) {
      talkDetailView({
        talkId: data.id.toString(),
        talkSlug: titleToSlug(data.name),
        talkState: getTrackEventTalkState(data),
      });
    }
  }, [data]);

  const scrollerRef = useRef<HTMLDivElement>(null);
  const handleScrollToAbout = () => {
    if (isDesktop) {
      scrollerRef.current?.scrollTo({
        top: aboutSectionRef.current?.offsetTop,
        behavior: "smooth",
      });
    } else {
      aboutSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLoadedAssetCounter = () => {
    setLoadedAssets((prev) => prev + 1);
  };

  return (
    <IonPage>
      <TitleHeader
        color="dark"
        defaultBack={generatePath(Routes.Schedule)}
        bgColor="white"
      >
        <TransitionFly show={titleIsShown} direction="bottom">
          {data?.name}
        </TransitionFly>
      </TitleHeader>
      <IonContent
        className="bg-white"
        scrollEvents={true}
        scrollY={!isDesktop}
        onIonScroll={() => !isDesktop && throttle(handleHeaderScroll, 50)} // TODO: check if we need to throttle more or less
      >
        <div className="flex flex-col bg-neutral-400 md:flex-row">
          <Intro
            ref={introRef}
            speaker={data}
            talk={
              data && {
                startDatetime: data.startDatetime,
                endDatetime: data.endDatetime,
                stage: data.stage,
                hasAIReflections: data.hasAIReflections,
              }
            }
            onScrollToAboutClick={handleScrollToAbout}
          />
          <div
            ref={scrollerRef}
            className="flex w-full flex-col md:h-screen md:overflow-y-auto md:pb-24"
          >
            <AIContent
              isLoading={isLoading}
              speakerData={data}
              hasEnded={hasTalkEnded}
              className="order-1 md:order-2"
            />
            <Media
              assets={data?.media}
              loadedAssets={loadedAssets}
              handleLoadedAssetCounter={handleLoadedAssetCounter}
              className="order-3 md:order-1"
              isLoading={isLoading}
            />
            <About
              description={data?.description}
              socials={data?.socials}
              ref={aboutSectionRef}
              hasMediaLoaded={loadedAssets === data?.media.length}
              isLoading={isLoading}
              className="order-2 md:order-3"
            />
          </div>
        </div>
      </IonContent>
      {hasTalkEnded && (
        <FooterButton
          bgColor="primary"
          onFooterClick={handleAddReflection}
          toolbarClass="border-neutral"
          startIcon="/assets/icons/ico-chatbubble.svg"
          endIcon="/assets/icons/ico-arrow-right.svg"
          textClass="text-[16px] text-neutral"
        >
          <FormattedMessage id="talk_detail.reflection.share" />
        </FooterButton>
      )}
    </IonPage>
  );
};

export default Talk;
