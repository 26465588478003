import { init, reactRouterV5BrowserTracingIntegration } from "@sentry/react";
import { createBrowserHistory } from "history";

import config from "config";

import { isProd } from "utils/env.utils";

const history = createBrowserHistory();

export const initWebSentry = () => {
  init({
    ...config.SENTRY,
    sampleRate: isProd() ? 0.1 : 1,
    integrations: [
      reactRouterV5BrowserTracingIntegration({
        history,
      }),
    ],
  });
};
