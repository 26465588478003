import { DataResponse } from "types/common.types";
import { DiscoBallTileResponse } from "types/discoball.types";

import RestApiService from "..";

export default {
  getDiscoBallTiles(this: RestApiService) {
    return this.api.get<DataResponse<Array<DiscoBallTileResponse>>>(
      `/discoball-tiles`,
    );
  },
};
