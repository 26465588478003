/* eslint-disable @typescript-eslint/no-explicit-any */
/*
THIS IS A MODIFIED VERSION OF THE ORIGINAL FILE
https://github.com/pmndrs/drei/blob/7e608767fe019e8413ac9376341dc1d744c20d98/src/web/PresentationControls.tsx
*/

import { a, easings, useSpring } from "@react-spring/three";
import { useFrame, useThree } from "@react-three/fiber";
import { useGesture } from "@use-gesture/react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MathUtils } from "three";

export type PresentationControlProps = {
  children?: React.ReactNode;
  idleTime?: number;
  rotationSpeed?: number;
};
export type PresentationControlHandle = {
  incrementZoom: (value: number) => void;
  decrementZoom: (value: number) => void;
};

const POLAR = [-Math.PI / 2, Math.PI / 2];
const MIN_Z = 0;
const MAX_Z = 8;

const MIN_SPEED = 0.25;
const MAX_SPEED = 1;
const IDLE_ROTATION_SPEED = 0.005;

const DEFAULT_SPRING_CONFIG = { mass: 1, tension: 80, friction: 30 };

export const PresentationControls = forwardRef<
  PresentationControlHandle,
  PresentationControlProps
>(
  (
    { children, idleTime = 5000, rotationSpeed: rotationSpeedPower = 1 },
    ref,
  ) => {
    const isIdle = useRef(false);
    const finishedInitialAnimation = useRef(false);

    const [controlsEnabled, setControlsEnabled] = useState(false);

    const idleTimerRef = useRef<NodeJS.Timeout>();
    const events = useThree((state) => state.events);
    const gl = useThree((state) => state.gl);

    const explDomElement = events.connected || gl.domElement;

    const { size } = useThree();

    const [rotationSpeed, rotationSpeedApi] = useSpring(() => ({
      speed: 0.005,
    }));

    const [spring, api] = useSpring(() => ({
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: 1,
      config: DEFAULT_SPRING_CONFIG,
    }));

    useEffect(() => {
      const handler = (e: Event) => e.preventDefault();
      document.addEventListener("gesturestart", handler);
      document.addEventListener("gesturechange", handler);
      document.addEventListener("gestureend", handler);
      return () => {
        document.removeEventListener("gesturestart", handler);
        document.removeEventListener("gesturechange", handler);
        document.removeEventListener("gestureend", handler);
      };
    }, []);

    useEffect(() => {
      // Initial animation

      api.stop();
      api.start({
        from: {
          position: [0, 0, -50],
          scale: 0,
        },
        to: {
          position: [0, 0, MIN_Z],
          scale: 1,
        },
        config: { duration: 3000, easing: easings.easeOutCubic },
        onRest: ({ finished }) => {
          if (finished) {
            setControlsEnabled(true);
          }
        },
      });

      rotationSpeedApi.stop();
      rotationSpeedApi.start({
        from: { speed: 0.1 },
        to: { speed: IDLE_ROTATION_SPEED },
        config: { duration: 3000, easing: easings.easeOutCirc },
      });
    }, [api, rotationSpeedApi]);

    useImperativeHandle(
      ref,
      () => ({
        incrementZoom: (value: number) => {
          const currentZ = spring.position.get()[2];
          const newZ = MathUtils.clamp(currentZ + value, MIN_Z, MAX_Z);
          api.start({ position: [0, 0, newZ] });
        },
        decrementZoom: (value: number) => {
          const currentZ = spring.position.get()[2];
          const newZ = MathUtils.clamp(currentZ - value, MIN_Z, MAX_Z);
          api.start({ position: [0, 0, newZ] });
        },
      }),
      [api, spring.position],
    );

    useFrame(() => {
      const currentSpeed = rotationSpeed.speed.get();
      if (
        isIdle.current ||
        (currentSpeed >= IDLE_ROTATION_SPEED &&
          !finishedInitialAnimation.current)
      ) {
        api.stop("rotation");
        api.start({
          rotation: [
            0,
            spring.rotation.get()[1] - currentSpeed * rotationSpeedPower,
            0,
          ],
          immediate: true,
        });

        if (
          !finishedInitialAnimation.current &&
          currentSpeed <= IDLE_ROTATION_SPEED
        ) {
          finishedInitialAnimation.current = true;
          isIdle.current = true;
          setControlsEnabled(true);
        }
      }
    });

    const checkForIdle = () => {
      clearTimeout(idleTimerRef.current);

      idleTimerRef.current = setTimeout(() => {
        // Reset the rotation

        const prevRotation = spring.rotation.get();
        api.start({
          position: [0, 0, 0],
          rotation: [0, prevRotation[1], prevRotation[2]],
          scale: 1,
          onRest: ({ finished }) => {
            if (finished) {
              isIdle.current = true;
            }
          },
        });
      }, idleTime);
    };

    const bind = useGesture(
      {
        onPinchStart: () => {
          isIdle.current = false;
          clearTimeout(idleTimerRef.current);
        },
        onPinchEnd: () => {
          checkForIdle();
        },
        onPinch: ({ offset: [s], memo, canceled }) => {
          isIdle.current = false;

          if (canceled) {
            checkForIdle();
            return;
          }

          const z = MathUtils.clamp(s - 1, MIN_Z, MAX_Z);
          api.start({
            position: [0, 0, z],
            config: { mass: 2, tension: 100, friction: 25 },
          });

          return memo;
        },
        onWheelStart: () => {
          isIdle.current = false;
          clearTimeout(idleTimerRef.current);
        },
        onWheelEnd: () => {
          checkForIdle();
        },
        onWheel: ({ event }) => {
          isIdle.current = false;

          const delta = -event.deltaY;
          const z = MathUtils.clamp(
            spring.position.get()[2] + delta * 0.216,
            MIN_Z,
            MAX_Z,
          );
          api.start({ position: [0, 0, z] });
        },
        onDragStart: ({ pinching }) => {
          if (pinching) {
            return;
          }

          isIdle.current = false;
          clearTimeout(idleTimerRef.current);
        },
        onDragEnd: () => {
          checkForIdle();
        },
        onDrag: ({
          pinching,
          cancel,
          delta: [x, y],
          memo: [oldY, oldX] = spring.rotation.animation.to,
        }) => {
          if (pinching) {
            return cancel();
          }

          const z = spring.position.get()[2];
          const speedPct = MathUtils.mapLinear(z, MIN_Z, MAX_Z, 0, 1);
          const speed = MathUtils.lerp(MIN_SPEED, MAX_SPEED, 1 - speedPct);

          x = oldX + (x / size.width) * Math.PI * speed;
          y = MathUtils.clamp(
            oldY + (y / size.height) * Math.PI * speed,
            POLAR[0],
            POLAR[1],
          );

          api.start({ rotation: [y, x, 0] });
          return [y, x];
        },
      },
      {
        target: explDomElement,
        enabled: controlsEnabled,
        drag: {
          filterTaps: true,
        },
        pinch: {
          scaleBounds: { min: MIN_Z + 1, max: MAX_Z + 1 },
          rubberband: true,
        },
      },
    );

    return (
      <a.group {...bind?.()} {...(spring as any)} ref={ref}>
        {children}
      </a.group>
    );
  },
);

PresentationControls.displayName = "PresentationControls";
