import { cn } from "utils/style.utils";

import { EventDay } from "types/event.types";

type Props = {
  title: EventDay;
  selectedDay: EventDay;
  setSelectedDay: (day: EventDay) => void;
};

const NavItem = ({ title, selectedDay, setSelectedDay }: Props) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        "w-full cursor-pointer items-center justify-center border-r border-neutral px-2 pb-[13px] pt-[11px] text-center uppercase transition-all duration-200 last:border-0 md:flex md:h-fit md:min-h-[64px] md:hover:brightness-95",
        selectedDay === title
          ? "bg-neutral text-neutral-400"
          : "bg-primary text-neutral",
      )}
      onClick={() => setSelectedDay(title)}
    >
      <h6 className="font-medium leading-6">{title}</h6>
    </div>
  );
};

export default NavItem;
