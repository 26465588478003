import { useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";
import { memo, useState } from "react";
import Lottie from "react-lottie";

import LottieBackgroundAnim from "assets/lottie/lottie-ubn-loop.json";

import { usePrefersReducedMotion, useWindowDimensions } from "hooks";

const HomeLottieAnimation = () => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const { height } = useWindowDimensions();

  const [isStopped, setIsStopped] = useState(false);

  useIonViewDidLeave(() => setIsStopped(true));
  useIonViewWillEnter(() => setIsStopped(false));

  return (
    <div className="fixed inset-0 -z-10 h-[100svh] w-[100svw] scale-[101%] bg-neutral transition-all duration-300">
      <Lottie
        style={{ width: "100svw", height: "100svh" }}
        speed={prefersReducedMotion ? 0 : 1}
        height={height + 40}
        isStopped={isStopped}
        segments={[0, 374]}
        options={{
          animationData: LottieBackgroundAnim,
          autoplay: true,
          loop: true,
          rendererSettings: {
            progressiveLoad: true,
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
      />
    </div>
  );
};

export default memo(HomeLottieAnimation);
