import { useIntl } from "react-intl";

import { useUser } from "queries";
import { ReactionTypes } from "types/reaction.types";
import { KeyTakeaway } from "types/talk.types";

import { ShareButton } from "components/@common/ShareButton";
import { Reactions } from "components/@reactions/Reactions";

import Wrapper from "./Wrapper";

type Props = {
  keyTakeaways: Array<KeyTakeaway>;
  speakerName: string;
  speakerId: number;
};

const KeyTakeaways = ({ keyTakeaways, speakerName, speakerId }: Props) => {
  const intl = useIntl();
  const { data: currentUser } = useUser();

  return (
    <Wrapper
      title={intl.formatMessage({
        id: "talk_detail.ai_content.key_takeaways.title",
      })}
    >
      <div>
        {keyTakeaways.map((takeaway) => (
          <div
            className="flex flex-col gap-2 border-b border-primary px-4 py-6"
            key={takeaway.id}
          >
            <div className="flex flex-row items-center justify-between">
              <h6>{speakerName}</h6>
              <ShareButton
                title={`${intl.formatMessage({ id: "talk_detail.ai_content.key_takeaways.title.singular" })}`}
                text={intl.formatMessage(
                  {
                    id: "talk_detail.ai_content.key_takeaways.share.text",
                  },
                  { content: takeaway.content },
                )}
                dialogTitle={`${intl.formatMessage({ id: "common.share" })} ${intl.formatMessage({ id: "talk_detail.ai_content.key_takeaways.title.singular" })}`}
              />
            </div>
            <div>{takeaway.content}</div>
            {!!takeaway.reactions && (
              <Reactions
                currentUser={currentUser}
                reactions={takeaway.reactions}
                type={ReactionTypes.KeyTakeaway}
                keyTakeawayId={takeaway.id}
                speakerId={speakerId}
                altColors
              />
            )}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default KeyTakeaways;
