import { useRef } from "react";

import { cn } from "utils/style.utils";

import { useIsDesktop } from "hooks";
import { SpeakerResponse } from "types/speaker.types";

import { MediaSkeleton } from "../MediaSkeleton";

type Props = {
  loadedAssets: number;
  handleLoadedAssetCounter: () => void;
  assets?: SpeakerResponse["media"];
  className?: string;
  isLoading?: boolean;
};

const Media = ({
  assets,
  loadedAssets,
  handleLoadedAssetCounter,
  className,
  isLoading,
}: Props) => {
  const isDesktop = useIsDesktop();
  const scrollerRef = useRef<HTMLDivElement>(null);
  if (isLoading || !assets)
    return (
      <div className={className}>
        <MediaSkeleton />
      </div>
    );
  return (
    <div
      ref={scrollerRef}
      className={cn(
        "relative flex h-fit min-h-80 w-full",
        isDesktop
          ? "snap-x snap-mandatory scroll-px-4 flex-row overflow-x-auto scroll-smooth border-b border-neutral"
          : "flex-col",
        className,
      )}
    >
      <div
        className="w-full"
        style={{
          display: assets.length !== loadedAssets ? "block" : "none",
        }}
      >
        <MediaSkeleton />
      </div>
      {assets.map((asset, index) => {
        const allAssetsLoaded = assets.length === loadedAssets;
        const commonProps = {
          style: {
            display: allAssetsLoaded ? "block" : "none",
          },
          className: cn(
            `w-full object-cover ${allAssetsLoaded ? "fade-in" : ""}`,
            isDesktop && "max-w-[245px]",
          ),
        };

        if (asset.type === "video") {
          return (
            <video
              key={index}
              {...commonProps}
              src={asset.url}
              autoPlay
              loop
              muted
              playsInline
              onLoadedData={handleLoadedAssetCounter}
            />
          );
        }
        return (
          <img
            key={index}
            {...commonProps}
            onLoad={handleLoadedAssetCounter}
            src={asset.url}
            alt="Talk photo"
          />
        );
      })}
    </div>
  );
};

export default Media;
