import { DataResponse } from "types/common.types";
import {
  GetUserResponse,
  PostUserParams,
  QRScan,
  QRScanResponse,
  UpdateUserParams,
  User,
} from "types/user.types";

import RestApiService from "..";

export default {
  getUserById(this: RestApiService, userId: number) {
    return this.api.get<DataResponse<GetUserResponse>>(`/users/${userId}`);
  },
  getUser(this: RestApiService) {
    return this.api.get<DataResponse<GetUserResponse>>(`/users/me`);
  },
  createUser(this: RestApiService, params: PostUserParams) {
    return this.api.post<DataResponse<QRScanResponse>>(`/users`, {
      data: params,
    });
  },
  updateUser(this: RestApiService, params: UpdateUserParams) {
    return this.api.put<DataResponse<User>>(`/users/me`, {
      data: params,
    });
  },
  createQRScan(this: RestApiService, params: QRScan) {
    return this.api.post<DataResponse<QRScanResponse>>(`/scan`, {
      data: params,
    });
  },
};
