import { format } from "date-fns";

import { EventDay } from "types/event.types";
import { PostKeyTakeawayReactionParams } from "types/reaction.types";
import { SpeakerBaseResponse, SpeakerResponse } from "types/speaker.types";

/**
 * Group the response from the speakers API call by day
 */
export const groupSpeakersByDay = (speakers: Array<SpeakerBaseResponse>) => {
  const speakersByDay: Record<EventDay, Array<SpeakerBaseResponse>> = {
    // Day names are capitalized to be aligned with the return type of new Intl.DateTimeFormat("en-US", { weekday: "long" }).format()
    Thursday: [],
    Friday: [],
    Saturday: [],
  };

  speakers.forEach((speaker) => {
    const eventDate = new Date(speaker.startDatetime);
    let day = format(eventDate, "EEEE") as EventDay;

    // Check if the time is before 4:00:00 AM
    // We want to group that event with the previous day
    if (eventDate.getHours() < 4) {
      // Move to the previous day
      eventDate.setDate(eventDate.getDate() - 1);
      day = format(eventDate, "EEEE") as EventDay;
    }

    if (!speakersByDay[day]) {
      speakersByDay[day] = [speaker];
    } else {
      speakersByDay[day].push(speaker);
    }
  });

  return speakersByDay;
};

export const updateKeyTakeawaysCache = (
  type: "add" | "remove" = "add",
  vars: PostKeyTakeawayReactionParams,
  prevData?: SpeakerResponse,
): SpeakerResponse => {
  if (
    !prevData ||
    prevData.id !== vars.speakerId ||
    !prevData.talk?.keyTakeaways
  )
    return prevData as SpeakerResponse;
  const updatedKeyTakeaways = prevData.talk.keyTakeaways.map((keyTakeaway) => {
    if (keyTakeaway.id === vars.keyTakeawayId) {
      if (!keyTakeaway.reactions?.length) {
        return {
          ...keyTakeaway,
          reactions:
            type === "add"
              ? [
                  {
                    emotion: vars.emotion,
                    count: 1,
                  },
                ]
              : [],
        };
      }

      const existingReaction = keyTakeaway.reactions.find(
        (reaction) => reaction.emotion === vars.emotion,
      );

      const newReactions = [
        ...keyTakeaway.reactions.filter(
          (reaction) => reaction.emotion !== vars.emotion,
        ),
        {
          emotion: vars.emotion,
          count:
            type === "add"
              ? (existingReaction?.count || 0) + 1
              : (existingReaction?.count || 1) - 1,
        },
      ];

      return {
        ...keyTakeaway,
        reactions: newReactions,
      };
    }
    return keyTakeaway;
  });
  return {
    ...prevData,
    talk: {
      ...prevData.talk,
      keyTakeaways: updatedKeyTakeaways,
    },
  };
};
