import { queryOptions } from "@tanstack/react-query";

import { authApiService } from "services";
import { StaleTime } from "services/react-query/types";
import { GetReactionsParams } from "types/reaction.types";

export const REACTION_QUERY_PREFIX = "reaction-query";

const fetchReactions = async (params: GetReactionsParams) => {
  const { data: reactions } = await authApiService.getReactions(params);
  return reactions.data || [];
};

export const ReactionQueries = {
  getReactions: (params: GetReactionsParams) =>
    queryOptions({
      queryKey: [REACTION_QUERY_PREFIX, params.type],
      queryFn: () => fetchReactions(params),
      staleTime: StaleTime.MINUTE,
    }),
};
