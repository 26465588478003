import {
  DataResponse,
  DateString,
  InfiniteDataResponse,
} from "types/common.types";
import {
  GetReflectionsParams,
  PostReflectionParams,
  Reflection,
} from "types/community.types";

import RestApiService from "..";

export default {
  getReflections(this: RestApiService, params: GetReflectionsParams) {
    return this.api.get<InfiniteDataResponse<Array<Reflection>>>(
      `/reflections`,
      { params },
    );
  },
  getNewReflections(this: RestApiService, lastChecked: DateString) {
    return this.api.get<DataResponse<Array<Reflection>>>(`/reflections/new`, {
      params: { lastChecked },
    });
  },
  createReflection(this: RestApiService, data: PostReflectionParams) {
    return this.api.post<DataResponse<Reflection>>(`/reflections`, { data });
  },
  deleteReflection(this: RestApiService, id: number) {
    return this.api.delete<DataResponse<Reflection>>(`/reflections/${id}`);
  },
};
