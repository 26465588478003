import { captureMessage } from "@sentry/react";

export const captureError = (
  message: string,
  error: Error,
  extra?: { [key: string]: unknown },
) => {
  captureMessage(message, {
    level: "error",
    extra: { error, ...extra },
  });
};
