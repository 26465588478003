import { Preferences } from "@capacitor/preferences";
import { AxiosInstance } from "axios";

import { TOKEN_KEY } from "constants/storage.constants";

const updateMethods = ["post", "put", "patch"];

export const createHeaderRequestInterceptor = (
  axios: AxiosInstance,
  addAuthHeader: boolean = true,
) => {
  axios.interceptors.request.use(async (request) => {
    request.headers = request.headers ?? {};

    const { value: jwtToken } = await Preferences.get({ key: TOKEN_KEY });

    if (jwtToken && addAuthHeader) {
      request.headers.Authorization = `Bearer ${jwtToken}`;
    }

    if (request.method && updateMethods.includes(request.method)) {
      request.headers["Content-Type"] = "application/json";
    }

    return request;
  });
};
