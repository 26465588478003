// comment out locales that should not be supported
import "@formatjs/intl-relativetimeformat/locale-data/en";

// import "@formatjs/intl-relativetimeformat/locale-data/nl";
import { createIntl, createIntlCache, IntlShape } from "react-intl";

import config from "config";

import MESSAGES_EN from "./translations/en.json";

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ["en-GB"];

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  "en-GB": createIntl({ locale: "en-GB", messages: MESSAGES_EN }, intlCache),
};

export { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, intl, locales };
