import config from "config/env";

// import { fetchAuthenticatedUserSession } from "services/api/axios/auth.interceptor";
import { GetMessagesResponse, SendMessagePayload } from "types/chat.types";
import { DataResponse } from "types/common.types";

import RestApiService from "..";

export default {
  getChat(this: RestApiService, chatId: string) {
    return this.api.get<DataResponse<GetMessagesResponse>>(`/chat/${chatId}`);
  },
  async sendChatMessage(this: RestApiService, payload: SendMessagePayload) {
    // axios' XHR adapter doesn't properly support stream responses, so using fetch here

    return fetch(`${config.API_BASE}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  },
};
