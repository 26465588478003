import { IonImg } from "@ionic/react";
import { memo, useState } from "react";

import { cn } from "utils/style.utils";

type Props = {
  src: string;
  alt?: string;
  className?: string;
  wrapClass?: string;
};

const Image = ({ wrapClass, className, src, alt }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      className={cn(
        "relative h-full w-full transition-all duration-200 ease-in",
        isLoaded ? "bg-neutral-50" : "bg-primary",
        wrapClass,
      )}
    >
      <IonImg
        src={src}
        alt={alt}
        onIonImgDidLoad={handleLoad}
        className={cn(
          "h-full w-full object-cover transition-all duration-200 ease-in",
          isLoaded ? "opacity-100" : "opacity-0",
          className,
        )}
      />
    </div>
  );
};

export default memo(Image);
