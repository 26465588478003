import { IonText } from "@ionic/react";

import { BUILD_ENV, COMMIT_HASH, PACKAGE_VERSION } from "config/version";

const AppVersion = () => {
  return (
    <div className="flex w-full justify-center pb-4">
      <IonText className="w-full text-center text-sm font-normal text-neutral-100">
        V{PACKAGE_VERSION} ({COMMIT_HASH}) - {BUILD_ENV}
      </IonText>
    </div>
  );
};

export default AppVersion;
