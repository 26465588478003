import { IonSkeletonText } from "@ionic/react";

const AboutSkeletonItem = () => {
  return (
    <IonSkeletonText
      animated={true}
      className="m-0 h-4 w-full bg-neutral-400 bg-opacity-10"
    />
  );
};

export default AboutSkeletonItem;
