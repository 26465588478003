import { AxiosInstance } from "axios";

import chatApi from "./chat/chat.api";
import discoballApi from "./discoball/discoball.api";
import reactionsApi from "./reactions/reactions.api";
import reflectionsApi from "./reflections/reflections.api";
import speakerApi from "./speaker/speaker.api";
import usersApi from "./users/users.api";

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // CHAT
  public getChat = chatApi.getChat;
  public sendChatMessage = chatApi.sendChatMessage;

  // REFLECTIONS
  public getReflections = reflectionsApi.getReflections;
  public getNewReflections = reflectionsApi.getNewReflections;
  public createReflection = reflectionsApi.createReflection;
  public deleteReflection = reflectionsApi.deleteReflection;

  // REACTIONS
  public getReactions = reactionsApi.getReactions;
  public createReflectionReaction = reactionsApi.createReflectionReaction;
  public createKeyTakeawayReaction = reactionsApi.createKeyTakeawayReaction;
  public deleteReflectionReaction = reactionsApi.deleteReflectionReaction;
  public deleteKeyTakeawayReaction = reactionsApi.deleteKeyTakeawayReaction;

  // SPEAKER
  public getSpeaker = speakerApi.getSpeaker;
  public getSpeakers = speakerApi.getSpeakers;

  // USERS
  public getUserById = usersApi.getUserById;
  public getUser = usersApi.getUser;
  public createUser = usersApi.createUser;
  public updateUser = usersApi.updateUser;
  public createQRScan = usersApi.createQRScan;

  // DISCOBALL
  public getDiscoBallTiles = discoballApi.getDiscoBallTiles;
}

export default RestApiService;
