import { ReactNode } from "react";

import TransitionWrapper from "./TransitionWrapper";

type Props = {
  show: boolean;
  children: ReactNode;
  className?: string;
  direction?: "top" | "bottom";
  delay?: number;
};

const TransitionFly = ({
  show,
  children,
  className,
  direction = "bottom",
  delay = 0,
}: Props) => {
  return (
    <TransitionWrapper
      show={show}
      className={className}
      delay={delay}
      isShownClass="translate-y-0"
      isHiddenClass={
        direction === "top" ? "-translate-y-full" : "translate-y-full"
      }
    >
      {children}
    </TransitionWrapper>
  );
};

export default TransitionFly;
