export const Routes = {
  User: "/user/:userId",
  Talk: "/talk/:talkId",
  EditProfile: "/edit-profile",
  Schedule: "/schedule",
  Chat: "/chat",
  Onboarding: "/onboarding",
  ShareReflection: "/share-reflection",
  Community: "/community",
  Home: "/home",
  N5Page: "/the-power-of-mx",
  Booth: "/booth",
} as const;
