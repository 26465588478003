import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ReflectionQueries } from "queries/reflections/queries";
import { updateReflectionsCache } from "queries/reflections/utils";
import { authApiService } from "services";
import {
  PostReflectionReactionParams,
  ReactionTypes,
} from "types/reaction.types";

import { ReactionQueries } from "./queries";
import { updateReactionsCacheRemove } from "./utils";

export const useDeleteReflectionReaction = (userId?: number) => {
  const queryClient = useQueryClient();

  const reactionKey = ReactionQueries.getReactions({
    type: ReactionTypes.Reflection,
  }).queryKey;
  const reflectionKey = ReflectionQueries.getReflections({
    userId,
  }).queryKey;

  const { isPending, isPaused, mutate, error } = useMutation({
    mutationFn: (params: PostReflectionReactionParams) =>
      authApiService.deleteReflectionReaction(params),
    onMutate: async (vars) => {
      // Snapshot the previous value
      const previousReactions = queryClient.getQueryData(reactionKey);
      const previousReflections = queryClient.getQueryData(reflectionKey);
      // Optimistically update the cache with the new reaction
      queryClient.setQueryData(reactionKey, (oldData) =>
        updateReactionsCacheRemove(vars, oldData),
      );
      queryClient.setQueryData(reflectionKey, (oldData) =>
        updateReflectionsCache("remove", vars, oldData),
      );

      return { previousReactions, previousReflections };
    },
    onError: (_err, _vars, context) => {
      queryClient.setQueryData(reactionKey, context?.previousReactions);
      queryClient.setQueryData(reflectionKey, context?.previousReflections);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: reactionKey });
      queryClient.invalidateQueries({ queryKey: reflectionKey });
    },
  });

  return {
    deleteReflectionReaction: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
