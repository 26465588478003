import { Animation, createAnimation, IonText } from "@ionic/react";
import { useEffect, useRef, useState } from "react";

import { cn } from "utils/style.utils";

import { emotionsMap } from "constants/emotions.constants";
import { Emotions } from "types/reaction.types";

type Props = {
  emotion: Emotions;
  onRemoveReaction: () => void;
  onAddReaction: () => void;
  myReactions: Array<string>;
  isOpen: boolean;
};

const ANIMATION_DURATION = 650;

const ReactionItem = ({
  emotion,
  onAddReaction,
  onRemoveReaction,
  myReactions,
  isOpen,
}: Props) => {
  const buttonEl = useRef<HTMLButtonElement | null>(null);
  const textEl = useRef<HTMLIonTextElement | null>(null);
  const buttonAnimation = useRef<Animation | null>(null);
  const textAnimation = useRef<Animation | null>(null);
  const enteringAnimation = useRef<Animation | null>(null);

  const [isClicked, setIsClicked] = useState(myReactions.includes(emotion));

  useEffect(
    () => setIsClicked(myReactions.includes(emotion)),
    [myReactions, emotion],
  );

  useEffect(() => {
    if (buttonAnimation.current === null && buttonEl.current) {
      buttonAnimation.current = createAnimation()
        .addElement(buttonEl.current)
        .duration(ANIMATION_DURATION)
        .iterations(1)
        .keyframes([
          { offset: 0, backgroundColor: "black" },
          { offset: 0.15, backgroundColor: "white" },
          { offset: 0.3, backgroundColor: "var(--ion-color-primary)" },
          { offset: 0.45, backgroundColor: "black" },
          { offset: 0.6, backgroundColor: "white" },
          { offset: 0.75, backgroundColor: "var(--ion-color-primary)" },
          { offset: 0.9, backgroundColor: "black" },
        ]);
    }
    if (textAnimation.current === null && textEl.current) {
      textAnimation.current = createAnimation()
        .addElement(textEl.current)
        .duration(ANIMATION_DURATION)
        .iterations(1)
        .keyframes([
          { offset: 0, color: "white" },
          { offset: 0.15, color: "var(--ion-color-primary)" },
          { offset: 0.3, color: "black" },
          { offset: 0.45, color: "white" },
          { offset: 0.6, color: "var(--ion-color-primary)" },
          { offset: 0.75, color: "black" },
          { offset: 0.9, color: "white" },
        ]);
    }
    if (enteringAnimation.current === null && buttonEl.current) {
      enteringAnimation.current = createAnimation()
        .addElement(buttonEl.current)
        .duration(400)
        .delay(150)
        .iterations(1)
        .easing("ease-out")
        .keyframes([
          { offset: 0, width: "0%" },
          { offset: 1, width: "100%" },
        ]);
    }
  }, [buttonEl, textEl]);

  const handleClick = () => {
    if (!isClicked) {
      buttonAnimation.current?.play();
      textAnimation.current?.play();
      onAddReaction();
      setTimeout(() => {
        setIsClicked(true);
      }, ANIMATION_DURATION);
    } else {
      buttonAnimation.current?.stop();
      textAnimation.current?.stop();
      onRemoveReaction();
      setIsClicked(false);
    }
  };

  useEffect(() => {
    if (isOpen && enteringAnimation.current) {
      enteringAnimation.current.play();
    }
  }, [isOpen]);

  return (
    <button
      ref={buttonEl}
      onClick={handleClick}
      className={cn(
        "flex h-[72px] flex-1 items-center justify-center place-self-center justify-self-center overflow-hidden",
        isClicked && "bg-black",
      )}
    >
      <IonText
        ref={textEl}
        className={cn(
          "whitespace-nowrap text-xl font-black italic leading-[56px]",
          isClicked && "text-white",
        )}
      >
        {emotionsMap[emotion]}
      </IonText>
    </button>
  );
};

export default ReactionItem;
