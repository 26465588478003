import { memo, useEffect, useMemo, useRef } from "react";

import { cn } from "utils/style.utils";

import { DateHHMMSS } from "types/common.types";
import { SpeakerBaseResponse } from "types/speaker.types";

import Card from "./Card";
import StartHour from "./StartHour";

type Props = {
  startHour: DateHHMMSS;
  speakers: Array<SpeakerBaseResponse>;
  shouldScrollIntoView: boolean;
  isLoading?: boolean;
};

const ORDERED_STAGES = ["MAIN", "SOOGOOD", "LIVING_ROOM"];

const Section = ({
  startHour,
  speakers,
  shouldScrollIntoView,
  isLoading,
}: Props) => {
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (shouldScrollIntoView) {
      // Add a delay to ensure that scroll doesn't happen too early
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 10);
    }
  }, [shouldScrollIntoView]);

  const sortedSpeakers = useMemo(
    () =>
      speakers.sort((a, b) => {
        return (
          ORDERED_STAGES.indexOf(a.stage) - ORDERED_STAGES.indexOf(b.stage)
        );
      }),
    [speakers],
  );

  return (
    <section className="relative">
      <StartHour startHour={startHour} ref={ref} isLoading={isLoading} />
      <div
        className={cn(
          "flex flex-col",
          sortedSpeakers.length === 2 && "xl:grid xl:grid-cols-2",
          sortedSpeakers.length === 3 && "xl:grid xl:grid-cols-3",
        )}
      >
        {!isLoading &&
          sortedSpeakers.map((speaker) => (
            <Card speaker={speaker} key={speaker.id} isLoading={isLoading} />
          ))}
      </div>
    </section>
  );
};

export default memo(Section);
