import { Animation, createAnimation } from "@ionic/react";
import { useEffect, useRef, useState } from "react";

import { emotionsMap } from "constants/emotions.constants";
import { useCheckLoginAction } from "hooks";
import { Reaction } from "types/reaction.types";
import { User } from "types/user.types";

type Props = {
  reaction: Pick<Reaction, "count" | "emotion">;
  myReactions: Array<string>;
  onRemoveReaction: () => void;
  onAddReaction: () => void;
  altColors?: boolean;
  currentUser?: User;
};

const InlineReactionItem = ({
  reaction,
  myReactions,
  onRemoveReaction,
  onAddReaction,
  altColors = false,
  currentUser,
}: Props) => {
  const reactionEl = useRef<HTMLButtonElement | null>(null);
  const animation = useRef<Animation | null>(null);

  const [isClicked, setIsClicked] = useState(
    myReactions.includes(reaction.emotion),
  );
  useEffect(() => {
    setIsClicked(myReactions.includes(reaction.emotion));
  }, [myReactions, reaction.emotion]);

  useEffect(() => {
    if (animation.current === null && reactionEl.current) {
      animation.current = createAnimation()
        .addElement(reactionEl.current)
        .duration(500)
        .iterations(2)
        .keyframes([
          {
            offset: 0,
            color: altColors ? "black" : "var(--ion-color-primary)",
            textShadow: "none",
          },
          {
            offset: 0.15,
            color: "white",
            textShadow:
              "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
          },
          {
            offset: 0.3,
            color: altColors ? "var(--ion-color-primary)" : "black",
            textShadow: "none",
          },
          {
            offset: 0.45,
            color: altColors ? "black" : "var(--ion-color-primary)",
            textShadow: "none",
          },
          {
            offset: 0.6,
            color: "white",
            textShadow:
              "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
          },
          {
            offset: 0.75,
            color: altColors ? "var(--ion-color-primary)" : "black",
            textShadow: "none",
          },
          {
            offset: 0.9,
            color: altColors ? "black" : "var(--ion-color-primary)",
            textShadow: "none",
          },
        ]);
    }
  }, [reactionEl, altColors]);

  const handleReaction = () => {
    if (!isClicked) {
      animation.current?.play();
      onAddReaction();
      setTimeout(() => {
        setIsClicked(true);
      }, 800);
    } else {
      animation.current?.stop();
      onRemoveReaction();
      setIsClicked(false);
    }
  };

  const handleReactIfLoggedIn = useCheckLoginAction({
    errorMessage: "reactions.not_logged_in",
    loginAction: handleReaction,
    user: currentUser,
  });
  return (
    <li>
      <button
        ref={reactionEl}
        onClick={handleReactIfLoggedIn}
        className={`flex flex-row flex-wrap gap-1 px-1 py-1 text-xs font-black uppercase italic ${isClicked ? (altColors ? "text-black" : "text-primary") : altColors ? "text-primary" : "text-neutral-100"} active:!text-black`}
        style={{
          WebkitTextStrokeWidth: "1px",
          WebkitTextStrokeColor: "transparent",
        }}
      >
        <span>{emotionsMap[reaction.emotion]}</span>
        <span>{reaction.count}</span>
      </button>
    </li>
  );
};

export default InlineReactionItem;
