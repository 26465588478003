import { useQuery } from "@tanstack/react-query";

import { UserQueries } from "./queries";

export const useUser = (config?: ReturnType<typeof UserQueries.getUser>) => {
  const { data, status, isLoading, error } = useQuery({
    ...UserQueries.getUser(),
    ...config,
  });

  return {
    data,
    status,
    isLoading,
    error,
  };
};
