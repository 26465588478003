import { useQuery } from "@tanstack/react-query";

import { SpeakerQueries } from "./queries";

export const useSpeakers = (
  config?: ReturnType<typeof SpeakerQueries.getSpeakers>,
) => {
  const { data, status, isLoading, error } = useQuery({
    ...SpeakerQueries.getSpeakers(),
    ...config,
  });

  return {
    data,
    status,
    isLoading,
    error,
  };
};
