import { memo, useMemo } from "react";

import { captureError } from "utils/sentry.utils";

import {
  useCreateKeyTakeawayReaction,
  useCreateReflectionReaction,
  useDeleteKeyTakeawayReaction,
  useDeleteReflectionReaction,
  useReactions,
} from "queries";
import { ReactionQueries } from "queries/reactions/queries";
import { Emotions, Reaction, ReactionTypes } from "types/reaction.types";
import { User } from "types/user.types";

import { ReactionsModal } from "../ReactionsModal";
import { useMostPopularReactions } from "./hooks";
import InlineReactionItem from "./InlineReactionItem";

type Props = {
  type: ReactionTypes;
  reflectionId?: number;
  keyTakeawayId?: number;
  speakerId?: number;
  reactions: Array<Reaction>;
  altColors?: boolean;
  currentUser?: User;
};

const Reactions = ({
  reactions,
  reflectionId,
  keyTakeawayId,
  speakerId,
  type,
  altColors = false,
  currentUser,
}: Props) => {
  const { createReflectionReaction } = useCreateReflectionReaction(
    currentUser?.id,
  );
  const { deleteReflectionReaction } = useDeleteReflectionReaction(
    currentUser?.id,
  );
  const { createKeyTakeawayReaction } = useCreateKeyTakeawayReaction();
  const { deleteKeyTakeawayReaction } = useDeleteKeyTakeawayReaction();

  const mostPopularReactions = useMostPopularReactions(reactions);

  const { data: userReactions } = useReactions(
    { type },
    {
      queryKey: ReactionQueries.getReactions({ type }).queryKey,
      enabled: !!currentUser,
    },
  );
  const myReactions = useMemo(() => {
    if (!userReactions) return [];

    const reactions =
      type === ReactionTypes.Reflection
        ? userReactions.filter(
            (reaction) => reaction.reflectionId === reflectionId,
          )
        : userReactions.filter(
            (reaction) => reaction.keyTakeawayId === keyTakeawayId,
          );

    return reactions.length ? reactions[0].emotions : [];
  }, [userReactions, type, reflectionId, keyTakeawayId]);

  const handleAddReaction = (emotion: Emotions) => {
    if (type === ReactionTypes.Reflection && !!reflectionId) {
      createReflectionReaction(
        {
          emotion,
          reflectionId: reflectionId,
        },
        {
          onError: (error) =>
            captureError("Error adding reflection reaction", error),
        },
      );
    } else if (
      type === ReactionTypes.KeyTakeaway &&
      !!keyTakeawayId &&
      !!speakerId
    ) {
      createKeyTakeawayReaction(
        {
          emotion,
          keyTakeawayId: keyTakeawayId,
          speakerId: speakerId,
        },
        {
          onError: (error) =>
            captureError("Error adding key takeaway reaction", error),
        },
      );
    }
  };

  const handleRemoveReaction = (emotion: Emotions) => {
    if (type === ReactionTypes.Reflection && !!reflectionId) {
      deleteReflectionReaction(
        {
          emotion,
          reflectionId: reflectionId,
        },
        {
          onError: (error) =>
            captureError("Error removing reflection reaction", error),
        },
      );
    } else if (
      type === ReactionTypes.KeyTakeaway &&
      !!keyTakeawayId &&
      !!speakerId
    ) {
      deleteKeyTakeawayReaction(
        {
          emotion,
          keyTakeawayId: keyTakeawayId,
          speakerId: speakerId,
        },
        {
          onError: (error) =>
            captureError("Error removing key takeaway reaction", error),
        },
      );
    }
  };

  return (
    <ul className="flex flex-row flex-wrap gap-4">
      {mostPopularReactions.map((reaction, index) => (
        <InlineReactionItem
          key={index}
          reaction={reaction}
          onAddReaction={() => handleAddReaction(reaction.emotion)}
          onRemoveReaction={() => handleRemoveReaction(reaction.emotion)}
          myReactions={myReactions}
          altColors={altColors}
          currentUser={currentUser}
        />
      ))}
      {!!currentUser && (
        <ReactionsModal
          myReactions={myReactions}
          onAddReaction={handleAddReaction}
          onRemoveReaction={handleRemoveReaction}
        />
      )}
    </ul>
  );
};

export default memo(Reactions);
