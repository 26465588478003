import { queryOptions } from "@tanstack/react-query";

import { captureError } from "utils/sentry.utils";

import { apiService } from "services";
import { StaleTime } from "services/react-query/types";

import { groupSpeakersByDay } from "./utils";

export const SPEAKER_QUERY_PREFIX = "speaker-query";

const fetchSpeaker = async (speakerId: string) => {
  const { data: response } = await apiService.getSpeaker(speakerId);

  return response.data;
};

const fetchSpeakers = async () => {
  const { data: response } = await apiService.getSpeakers();

  const speakers = response.data;

  return groupSpeakersByDay(speakers);
};

export const SpeakerQueries = {
  getSpeakerById: (id: string) =>
    queryOptions({
      queryKey: [SPEAKER_QUERY_PREFIX, id],
      queryFn: () =>
        fetchSpeaker(id).catch((error) => {
          captureError("Error while getting speaker by id", error);
          throw error;
        }),
      meta: { localStorage: true },
      staleTime: StaleTime.MINUTE,
    }),
  getSpeakers: () =>
    queryOptions({
      queryKey: [SPEAKER_QUERY_PREFIX],
      queryFn: () =>
        fetchSpeakers().catch((error) => {
          captureError("Error while getting speakers", error);
          throw error;
        }),
      meta: { localStorage: true },
      staleTime: StaleTime.MINUTE,
    }),
};
