import { cn } from "utils/style.utils";

import { useIsDesktop } from "hooks";
import { SpeakerResponse } from "types/speaker.types";

import { AIContentSkeleton } from "../AIContentSkeleton";
import Challenges from "./Challenges";
import KeyTakeaways from "./KeyTakeaways";
import Summary from "./Summary";
import ThoughtProvokingQuestions from "./ThoughtProvokingQuestions";
import Warning from "./Warning";

type Props = {
  hasEnded: boolean;
  speakerData?: SpeakerResponse;
  className?: string;
  isLoading?: boolean;
};

const AIContent = ({ speakerData, hasEnded, className, isLoading }: Props) => {
  const isDesktop = useIsDesktop();

  if (isLoading || !speakerData)
    return (
      <div className={className}>
        <AIContentSkeleton />
      </div>
    );

  const hasAIContent = speakerData.hasAIReflections;
  const { talk, name, id } = speakerData;

  return (
    <div
      className={cn(
        "flex flex-col",
        isDesktop && "gap-6 bg-secondary p-4",
        className,
      )}
    >
      {hasAIContent && <Warning />}
      <Summary
        hasEnded={hasEnded}
        summary={talk?.summary}
        hasAIContent={hasAIContent}
      />
      {talk && (
        <>
          {!!talk.keyTakeaways?.length && (
            <KeyTakeaways
              keyTakeaways={talk.keyTakeaways}
              speakerName={name}
              speakerId={id}
            />
          )}
          {!!talk.thoughtProvokingQuestions?.length && (
            <ThoughtProvokingQuestions
              thoughtProvokingQuestions={talk.thoughtProvokingQuestions}
            />
          )}
          {!!talk.challenges?.length && (
            <Challenges challenges={talk.challenges} />
          )}
        </>
      )}
    </div>
  );
};

export default AIContent;
