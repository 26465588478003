import { memo, useMemo } from "react";

import { objectKeys } from "utils/common.utils";

import { SpeakerBaseResponse } from "types/speaker.types";

import Section from "./Section";
import {
  groupSpeakersByStartHour,
  isCurrentSpeakerTalk,
  parseTimeToMinutes,
} from "./utils";

type Props = {
  speakers?: Array<SpeakerBaseResponse>;
  isLoading?: boolean;
};

const Speakers = ({ speakers, isLoading }: Props) => {
  const { sortedGroupSpeakerKeys, groupedSpeakers } = useMemo(() => {
    const groupedSpeakers = speakers ? groupSpeakersByStartHour(speakers) : {};

    const sortedGroupSpeakerKeys = objectKeys(groupedSpeakers).sort((a, b) => {
      const minutesA = parseTimeToMinutes(a);
      const minutesB = parseTimeToMinutes(b);

      // Adjust times to handle special cases for sorting
      // Midnight to 4 AM (0 to 240 minutes) should come after 11 PM (1380 minutes)
      const adjustedMinutesA = minutesA < 240 ? minutesA + 1440 : minutesA;
      const adjustedMinutesB = minutesB < 240 ? minutesB + 1440 : minutesB;
      return adjustedMinutesA - adjustedMinutesB;
    });

    return { sortedGroupSpeakerKeys, groupedSpeakers };
  }, [speakers]);

  return (
    <div className="speakers flex flex-col">
      {sortedGroupSpeakerKeys.map((startHour, idx) => (
        <Section
          key={idx}
          startHour={startHour}
          speakers={groupedSpeakers[startHour]}
          shouldScrollIntoView={isCurrentSpeakerTalk(startHour)}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};

export default memo(Speakers);
