import { Style } from "@capacitor/status-bar";
import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Routes } from "constants/routes.constants";
import { useChatbot, useColorSafeAreas } from "hooks";
import { useUser } from "queries";

import { ChatInput } from "components/@chatbot";
import { ChatClearButton, ChatMessages } from "components/@chatbot/components";
import { TitleHeader } from "components/@common/TitleHeader";

const Chat = () => {
  useColorSafeAreas({
    currentRoute: Routes.Chat,
    statusBar: "#d2b4ff",
    navigationBar: "#d2b4ff",
    iconsColor: Style.Light,
  });

  const { data: user, isLoading: userIsLoading } = useUser();
  const [currentInput, setCurrentInput] = useState<string>("");
  const {
    chatMessages,
    clearChat,
    scrollRef,
    useSuggestion,
    chatInputRef,
    scrollDown,
    focusChatInput,
    stopMessage,
    sendMessage,
  } = useChatbot({ currentInput, setCurrentInput });

  const [titleHeight, setTitleHeight] = useState<number>(250);
  const contentRef = useRef<HTMLElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleTitleHeight = () => {
      if (contentRef.current && suggestionsRef.current) {
        setTitleHeight(
          contentRef.current.clientHeight - suggestionsRef.current.clientHeight,
        );
      }
    };
    window.addEventListener("resize", handleTitleHeight);
    return () => window.removeEventListener("resize", handleTitleHeight);
  });

  return (
    <IonPage>
      <TitleHeader
        color="primary"
        bgColor="secondary"
        endSlot={
          !!chatMessages.length && <ChatClearButton onClearChat={clearChat} />
        }
      >
        <FormattedMessage id="chatbot.ask.title" />
      </TitleHeader>

      <IonContent color="secondary">
        <section
          ref={contentRef}
          className="relative flex h-full w-full flex-col !justify-end overflow-scroll bg-secondary"
        >
          <ChatMessages
            scrollRef={scrollRef}
            userIsLoading={userIsLoading}
            user={user}
            suggestionsRef={suggestionsRef}
            titleHeight={titleHeight}
            useSuggestion={useSuggestion}
          />
        </section>
      </IonContent>
      <ChatInput
        isDetail={!!chatMessages.length}
        currentInput={currentInput}
        setCurrentInput={setCurrentInput}
        onSendMessage={scrollDown}
        chatInputRef={chatInputRef}
        handleSendMessage={sendMessage}
        handleFocusChatInput={focusChatInput}
        onStopMessage={stopMessage}
      />
    </IonPage>
  );
};

export default Chat;
