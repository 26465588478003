import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...classes: Array<ClassValue>) => twMerge(clsx(...classes));

export const adjustShadowRootStyles = (
  [outerSelector, innerSelector]: [string, string],
  style: string,
) => {
  const element = document
    .querySelector(outerSelector)
    ?.shadowRoot?.querySelector(innerSelector);

  element?.setAttribute("style", style);
};
