import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";

import { cn } from "utils/style.utils";

import { SpeakerResponse } from "types/speaker.types";

import { AboutSkeleton } from "../AboutSkeleton";
import Socials from "./Socials";

type Props = {
  hasMediaLoaded: boolean;
  description?: SpeakerResponse["description"];
  socials?: SpeakerResponse["socials"];
  isLoading?: boolean;
  className?: string;
};

const About = forwardRef<HTMLDivElement, Props>(
  ({ description, socials, hasMediaLoaded, className, isLoading }, ref) => {
    if (!hasMediaLoaded || isLoading || !description || !socials)
      return (
        <div className={cn("w-full", className)} ref={ref}>
          <AboutSkeleton />
        </div>
      );

    return (
      <div
        ref={ref}
        className={cn("flex scroll-mt-6 flex-col gap-6 px-4 py-6", className)}
      >
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-medium uppercase">
            <FormattedMessage id="talk_detail.about.title" />
          </h4>
          <p className="text-base leading-[27px]">{description}</p>
        </div>
        <Socials socials={socials} />
      </div>
    );
  },
);

About.displayName = "About";

export default About;
