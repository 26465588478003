import { IonRouterLink, IonSkeletonText } from "@ionic/react";
import { memo } from "react";
import { generatePath } from "react-router";

import { cn } from "utils/style.utils";

import { STAGE_MAP } from "constants/event.constants";
import { Routes } from "constants/routes.constants";
import { useIsDesktop } from "hooks";
import { SpeakerBaseResponse } from "types/speaker.types";

import InsightsAvailable from "./InsightsAvailable";
import SpeakerImage from "./SpeakerImage";

type Props = {
  speaker: SpeakerBaseResponse;
  isLoading?: boolean;
};

const Card = ({ speaker, isLoading }: Props) => {
  const isDesktop = useIsDesktop();
  return (
    <IonRouterLink
      routerLink={generatePath(Routes.Talk, { talkId: speaker.id })}
      routerDirection="forward"
      key={speaker.id}
      className={cn(
        "border-b border-neutral transition-all duration-200",
        isDesktop &&
          "flex-1 grow basis-0 border-b hover:brightness-95 xl:border-r xl:last:border-r-0",
      )}
    >
      <div className="flex flex-row text-neutral-400 sm:h-48 xl:h-52 xl:min-h-52">
        <SpeakerImage imgSrc={speaker.imageUrl} isLoading={isLoading} />
        <div className="flex flex-1 flex-col justify-center gap-2 bg-primary p-4">
          <div>
            <div className="flex flex-col">
              <span className="text-sm font-medium uppercase leading-[24px] text-neutral-400">
                {isLoading ? (
                  <IonSkeletonText
                    animated
                    className="h-5 w-1/3 bg-red-900 bg-opacity-10"
                  />
                ) : speaker.hasAIReflections ? (
                  <InsightsAvailable />
                ) : (
                  STAGE_MAP[speaker.stage]
                )}
              </span>
              {isLoading ? (
                <IonSkeletonText
                  animated
                  className="h-9 w-3/4 bg-red-900 bg-opacity-10"
                />
              ) : (
                <span className="pb-2 text-xl font-medium uppercase leading-7 text-neutral [overflow-wrap:anywhere]">
                  {speaker.name}
                </span>
              )}
            </div>
            <div>
              {isLoading ? (
                <IonSkeletonText
                  animated
                  className="h-5 w-1/2 bg-red-900 bg-opacity-10"
                />
              ) : (
                <span className="flex flex-1 items-center text-sm leading-[24px] text-neutral">
                  {speaker.role}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </IonRouterLink>
  );
};

export default memo(Card);
