import { IonIcon } from "@ionic/react";

type Props = {
  currentInput: string;
  onSend: () => void;
};

const ChatSendButton = ({ currentInput, onSend }: Props) => {
  return (
    <button
      onClick={onSend}
      disabled={!currentInput.trim()}
      className="mr-6 flex h-full items-center transition-all duration-200 active:translate-x-2 disabled:opacity-50 disabled:active:translate-x-0"
    >
      <IonIcon src="/assets/icons/ico-arrow-right.svg" color="primary" />
    </button>
  );
};

export default ChatSendButton;
