import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SpeakerQueries } from "queries/speaker/queries";
import { updateKeyTakeawaysCache } from "queries/speaker/utils";
import { authApiService } from "services";
import {
  PostKeyTakeawayReactionParams,
  ReactionTypes,
} from "types/reaction.types";

import { ReactionQueries } from "./queries";
import { updateReactionsCacheAdd } from "./utils";

export const useCreateKeyTakeawayReaction = () => {
  const queryClient = useQueryClient();

  const reactionKey = ReactionQueries.getReactions({
    type: ReactionTypes.KeyTakeaway,
  }).queryKey;

  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: (params: PostKeyTakeawayReactionParams) =>
      authApiService.createKeyTakeawayReaction(params),
    onMutate: async (vars) => {
      const speakerKey = SpeakerQueries.getSpeakerById(
        String(vars.speakerId),
      ).queryKey;

      // Snapshot the previous value
      const previousReactions = queryClient.getQueryData(reactionKey);
      const previousSpeaker = queryClient.getQueryData(speakerKey);

      // Optimistically update the cache with the new reaction
      queryClient.setQueryData(reactionKey, (oldData) => {
        return updateReactionsCacheAdd(
          ReactionTypes.KeyTakeaway,
          vars,
          oldData,
        );
      });
      queryClient.setQueryData(speakerKey, (oldData) =>
        updateKeyTakeawaysCache("add", vars, oldData),
      );

      return { previousReactions, previousSpeaker };
    },
    onError: (_err, vars, context) => {
      queryClient.setQueryData(reactionKey, context?.previousReactions);
      queryClient.setQueryData(
        SpeakerQueries.getSpeakerById(String(vars.speakerId)).queryKey,
        context?.previousSpeaker,
      );
    },
    onSettled: (data, _err, vars) => {
      if (data?.status !== 201) {
        queryClient.invalidateQueries({ queryKey: reactionKey });
        queryClient.invalidateQueries({
          queryKey: SpeakerQueries.getSpeakerById(String(vars.speakerId))
            .queryKey,
        });
      }
    },
  });

  return {
    createKeyTakeawayReaction: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
