import { format } from "date-fns";

import { EVENT_DATE_MAP } from "constants/event.constants";
import { DateHHMMSS } from "types/common.types";
import { SpeakerBaseResponse } from "types/speaker.types";

/**
 * Groups speakers by start hour based on the 'startDatetime' field
 */
export const groupSpeakersByStartHour = (
  events: Array<SpeakerBaseResponse>,
) => {
  return events.reduce(
    (acc: Record<DateHHMMSS, Array<SpeakerBaseResponse>>, event) => {
      const date = new Date(event.startDatetime);
      // Using toLocaleTimeString to get the time in 12-hour format with AM/PM in 'HH:MM:SS' format
      const hourKey = format(date, "hh:mm:ss aa") as DateHHMMSS;

      if (!acc[hourKey]) {
        acc[hourKey] = [];
      }

      acc[hourKey].push(event);
      return acc;
    },
    {},
  );
};

/**
 * Returns true for speakers who are currently giving their talk based on the start hour
 * @param startHour - The start hour of the speaker's talk
 * @returns boolean
 */
export const isCurrentSpeakerTalk = (startHour: DateHHMMSS) => {
  const now = new Date();
  const currentDate = format(now, "yyyy-MM-dd");

  // check if the current date is one of the event dates
  const isEventDate =
    EVENT_DATE_MAP[currentDate as keyof typeof EVENT_DATE_MAP];

  // if the current date is not an event date, return false
  if (!isEventDate) {
    return false;
  }

  const nowFormatted = format(now, "hh:mm:ss aa");
  const dayPart = nowFormatted.split(" ")[1];

  // if the current time is AM, return false because there are no talks in the morning
  if (dayPart === "AM") {
    return false;
  }

  // compare the current time (hour) with the startHour
  const currentHour = nowFormatted.split(":")[0]; // get the current hour
  const [hour] = startHour.split(":"); // get the start hour

  if (currentHour === hour) {
    return true;
  }

  return false;
};

/**
 * Parses a time string in the format "HH:MM:SS AM/PM" and converts it to minutes since midnight.
 *
 * @param {string} timeString - The time string to parse, e.g., "09:00:00 PM"
 * @returns {number} The number of minutes since midnight
 * @throws {Error} If the time string format is invalid
 *
 * @example
 * parseTime("09:00:00 PM") // returns 1260 (9 PM is 21:00, which is 21 * 60 = 1260 minutes)
 * parseTime("12:30:00 AM") // returns 30 (12:30 AM is 30 minutes after midnight)
 */
export const parseTimeToMinutes = (timeString: string): number => {
  const [time, period] = timeString.split(" ");

  const timeSplit = time.split(":");

  let hours = Number(timeSplit[0]);
  const minutes = Number(timeSplit[1]);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return hours * 60 + minutes;
};
