import * as THREE from "three";

import { isDev } from "utils/env.utils";

import { BenchmarkTier } from "../BenckmarkProvider";

export function calculateAmountOfMirrors(
  radius: number,
  mirrorSize: number,
  minSpacing: number,
) {
  const totalMirrorSize = mirrorSize + minSpacing;
  const bandCount = Math.floor((Math.PI * radius) / totalMirrorSize); // Number of horizontal bands
  let mirrorCount = 0;

  for (let band = 0; band <= bandCount; band++) {
    const phi = (band / bandCount) * Math.PI; // Angle for the current band
    const r = radius * Math.sin(phi); // Radius of the band at height y
    const circumference = 2 * Math.PI * r;
    const mirrorsInBand = Math.floor(circumference / totalMirrorSize);
    mirrorCount += mirrorsInBand;
  }

  return mirrorCount;
}

export function createMirrorsOnSphere(
  radius: number,
  mirrorSize: number,
  minSpacing: number,
) {
  const dummy = new THREE.Object3D();
  const mirrorGeometry = new THREE.BoxGeometry(mirrorSize, mirrorSize, 0.01);

  const geometries = [];
  const totalMirrorSize = mirrorSize + minSpacing;
  const bandCount = Math.floor((Math.PI * radius) / totalMirrorSize); // Number of horizontal bands

  for (let band = 0; band <= bandCount; band++) {
    const phi = (band / bandCount) * Math.PI; // Angle for the current band
    const y = radius * Math.cos(phi); // Y position of the band
    const r = radius * Math.sin(phi); // Radius of the band at height y
    const circumference = 2 * Math.PI * r;
    const mirrorsInBand = Math.floor(circumference / totalMirrorSize);
    const horizontalSpacing = (2 * Math.PI) / mirrorsInBand;

    // Offset each band so the center is not aligned with the previous band
    const offset = band % 2 === 0 ? 0 : horizontalSpacing * 0.5;

    for (let i = 0; i < mirrorsInBand; i++) {
      const theta = i * horizontalSpacing + offset;
      const x = r * Math.cos(theta);
      const z = r * Math.sin(theta);

      dummy.position.set(x, y, z);
      dummy.lookAt(0, 0, 0); // Make the mirror face the center
      dummy.updateMatrix();

      const clonedMirrorGeometry = mirrorGeometry.clone();
      clonedMirrorGeometry.applyMatrix4(dummy.matrix);
      clonedMirrorGeometry.userData = {
        initialPosition: new THREE.Vector3(x, y, z),
      };
      geometries.push(clonedMirrorGeometry);
    }
  }

  // Dispose of the initial mirror geometry
  mirrorGeometry.dispose();

  return geometries;
}

export function mirrorLimit(tier: BenchmarkTier) {
  let limit = 2000;
  if (tier === BenchmarkTier.High) {
    limit = 2000;
  } else if (tier === BenchmarkTier.Medium) {
    limit = 300;
  } else {
    limit = 200;
  }

  if (isDev()) {
    console.info(`Device tier: ${tier} - Mirror limit: ${limit}`);
  }

  return limit;
}

/*
 * Generate a random number between 0 and 1 using a seed
 * @param seed The seed to use for the random number generator
 * @returns The random number
 */
export function seededRandom(seed: number) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

/*
 * Scramble an array using the Fisher-Yates algorithm with a seed
 * @param array The array to scramble
 * @param seed The seed to use for the random number generator
 * @returns The scrambled array
 */
export function scramble<T>(array: Array<T>, seed: number): Array<T> {
  const scrambledArray = [...array]; // Make a copy of the array to avoid mutating the original
  for (let i = scrambledArray.length - 1; i > 0; i--) {
    const j = Math.floor(seededRandom(seed + i) * (i + 1));
    [scrambledArray[i], scrambledArray[j]] = [
      scrambledArray[j],
      scrambledArray[i],
    ];
  }
  return scrambledArray;
}
