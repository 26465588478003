import { IonIcon } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";

import { Talk } from "types/talk.types";

import Wrapper from "./Wrapper";

type Props = {
  hasEnded: boolean;
  hasAIContent: boolean;
  summary?: Talk["summary"];
};

const Summary = ({ hasEnded, hasAIContent, summary }: Props) => {
  const intl = useIntl();

  if (hasAIContent) {
    return (
      <Wrapper
        title={intl.formatMessage({
          id: "talk_detail.ai_content.summary.title",
        })}
      >
        <div className="border-b border-primary">
          <p className="px-4 py-6 leading-[27px]">{summary}</p>
        </div>
      </Wrapper>
    );
  } else
    return (
      <div className="flex flex-col gap-2 bg-secondary p-6 text-primary">
        <div className="flex flex-row items-center gap-2 bg-secondary text-sm">
          <IonIcon src={"/assets/icons/ico-AI.svg"} color="primary" />
          <h4 className="text-sm font-medium uppercase">
            <FormattedMessage id="talk_detail.ai_content.summary.title" />
          </h4>
        </div>
        <div>
          <p className="text-base leading-[27px]">
            {hasEnded ? (
              <FormattedMessage id="talk_detail.ai_content.summary.pre_ai_content" />
            ) : (
              <FormattedMessage id="talk_detail.ai_content.summary.pre_talk_content" />
            )}
          </p>
        </div>
      </div>
    );
};

export default Summary;
