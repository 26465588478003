import { IonSkeletonText } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import AboutSkeletonItem from "./AboutSkeletonItem";

const AboutSkeleton = () => {
  return (
    <div className="flex w-full scroll-mt-6 flex-col gap-6 px-4 py-6">
      <h4 className="text-sm font-medium uppercase">
        <FormattedMessage id="talk_detail.about.title" />
      </h4>
      <div className="flex flex-col gap-4">
        <AboutSkeletonItem />
        <AboutSkeletonItem />
        <AboutSkeletonItem />
        <AboutSkeletonItem />
        <AboutSkeletonItem />
        <AboutSkeletonItem />
        <IonSkeletonText
          animated={true}
          className="m-0 h-4 w-1/2 bg-neutral-400 bg-opacity-10"
        />
      </div>
    </div>
  );
};

export default AboutSkeleton;
