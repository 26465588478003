import { InfiniteData } from "@tanstack/react-query";

import { InfiniteDataResponse } from "types/common.types";
import { Reflection } from "types/community.types";
import { PostReflectionReactionParams, Reaction } from "types/reaction.types";
import { UpdateUserParams } from "types/user.types";

export const updateReflectionsCache = (
  type: "add" | "remove" = "add",
  vars: PostReflectionReactionParams,
  prevReflectionData?: InfiniteData<
    InfiniteDataResponse<Array<Reflection>>,
    unknown
  >,
): InfiniteData<InfiniteDataResponse<Array<Reflection>>, unknown> => {
  if (!prevReflectionData)
    return {} as InfiniteData<InfiniteDataResponse<Array<Reflection>>, unknown>;

  const updatedPages = prevReflectionData.pages.map((page) => {
    const updatedData = page.data.map((reflection) => {
      if (reflection.id === vars.reflectionId) {
        const existingReaction = reflection.reactions.find(
          (reaction) => reaction.emotion === vars.emotion,
        );

        const newReactions = [
          ...reflection.reactions.filter(
            (reaction) => reaction.emotion !== vars.emotion,
          ),
          {
            emotion: vars.emotion,
            count:
              type === "add"
                ? (existingReaction?.count || 0) + 1
                : (existingReaction?.count || 1) - 1,
          } as Reaction,
        ];

        return {
          ...reflection,
          reactions: newReactions,
        };
      }
      return reflection;
    });
    return {
      ...page,
      data: updatedData,
    };
  });

  return {
    ...prevReflectionData,
    pages: updatedPages,
  };
};

export const updateReflectionsCacheRemove = (
  prevReflectionData:
    | InfiniteData<InfiniteDataResponse<Array<Reflection>>, unknown>
    | undefined,
  reflectionId?: number,
) => {
  if (!prevReflectionData) return prevReflectionData;
  const newPages = prevReflectionData?.pages.map((page) => {
    page.data = page.data.filter(
      (reflection) => reflection.id !== reflectionId,
    );
    return page;
  });
  return { ...prevReflectionData, pages: newPages };
};

export const updateReflectionsUserCache = (
  prevReflectionData:
    | InfiniteData<InfiniteDataResponse<Array<Reflection>>, unknown>
    | undefined,
  userData: UpdateUserParams,
  userId?: number,
) => {
  if (!prevReflectionData || !userId) return prevReflectionData;
  prevReflectionData.pages.forEach((page) => {
    page.data.forEach((reflection) => {
      if (reflection.user.id === userId) {
        reflection.user = {
          ...reflection.user,
          ...userData,
        };
      }
    });
  });
  return prevReflectionData;
};
