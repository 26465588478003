import { IonIcon } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";

import { cn } from "utils/style.utils";

type Props = {
  scrollerRef: React.RefObject<HTMLDivElement>;
  scrollAmount?: number;
  color?: string;
  bgColor?: string;
};

const ScrollLeftRight = ({
  scrollerRef,
  scrollAmount = 100,
  color = "primary",
  bgColor = "hover:bg-primary/10 active:bg-primary/20",
}: Props) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);

  const handleScroller = useCallback(() => {
    // Handle isScrollable
    if (!scrollerRef.current) {
      setIsScrollable(false);
    } else {
      setIsScrollable(
        scrollerRef.current.scrollWidth > scrollerRef.current.clientWidth,
      );
    }
    // Handle isScrolledToLeft
    if (!scrollerRef.current || !isScrollable) {
      setIsScrolledToLeft(true);
    } else {
      setIsScrolledToLeft(scrollerRef.current.scrollLeft === 0);
    }
    // Handle isScrolledToRight
    if (!scrollerRef.current || !isScrollable) {
      setIsScrolledToRight(true);
    } else {
      setIsScrolledToRight(
        scrollerRef.current.scrollLeft + scrollerRef.current.clientWidth ===
          scrollerRef.current.scrollWidth,
      );
    }
  }, [isScrollable, scrollerRef]);

  useEffect(() => {
    handleScroller();
    window.addEventListener("resize", handleScroller);
    scrollerRef.current?.addEventListener("scroll", handleScroller);
    return () => {
      window.removeEventListener("resize", handleScroller);
      scrollerRef.current?.removeEventListener("scroll", handleScroller);
    };
  }, [handleScroller, isScrollable, scrollerRef]);

  const scrollLeft = () => {
    if (scrollerRef.current && isScrollable) {
      scrollerRef.current.scrollLeft -= scrollAmount;
    }
    handleScroller();
  };
  const scrollRight = () => {
    if (scrollerRef.current && isScrollable) {
      scrollerRef.current.scrollLeft += scrollAmount;
    }
    handleScroller();
  };

  const buttonStyle =
    "h-6 w-6 hover:brightness-95 active:brightness-90 disabled:cursor-not-allowed disabled:opacity-40 transition-all duration-200";

  return (
    <div className={"flex items-center gap-1"}>
      <button
        onClick={scrollLeft}
        onMouseDown={(e) => e.preventDefault()}
        disabled={!isScrollable || isScrolledToLeft}
        className={cn(buttonStyle, bgColor)}
      >
        <IonIcon src="/assets/icons/ico-arrow-left.svg" color={color} />
      </button>
      <button
        onClick={scrollRight}
        disabled={!isScrollable || isScrolledToRight}
        className={cn(buttonStyle, bgColor)}
      >
        <IonIcon src="/assets/icons/ico-arrow-right.svg" color={color} />
      </button>
    </div>
  );
};

export default ScrollLeftRight;
