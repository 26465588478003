import { useQuery } from "@tanstack/react-query";

import { ChatQueries } from "./queries";

export const useChatById = (
  chatId: string,
  config?: ReturnType<typeof ChatQueries.getChatById>,
) => {
  const { data, status, isLoading, isFetching, error, isError, refetch } =
    useQuery({
      ...ChatQueries.getChatById(chatId),
      ...config,
      enabled: (config?.enabled ?? true) && !!chatId,
    });

  return {
    data: data?.messages,
    status,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
};
