import { queryOptions } from "@tanstack/react-query";

import { apiService, authApiService } from "services";
import { StaleTime } from "services/react-query/types";

export const USER_QUERY_PREFIX = "user-query";

const fetchUserById = async (userId: number) => {
  const { data: response } = await apiService.getUserById(userId);
  return response.data || [];
};

const fetchUser = async () => {
  const { data: response } = await authApiService.getUser();
  return response.data || [];
};

export const UserQueries = {
  getUserById: (userId: number) =>
    queryOptions({
      queryKey: [USER_QUERY_PREFIX, userId],
      queryFn: () => fetchUserById(userId),
      staleTime: StaleTime.MINUTE,
      meta: { localStorage: true },
    }),
  getUser: () =>
    queryOptions({
      queryKey: [USER_QUERY_PREFIX],
      queryFn: () => fetchUser(),
      staleTime: StaleTime.ONE_HOUR,
      meta: { localStorage: true },
    }),
};
