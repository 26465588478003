import { IonSkeletonText } from "@ionic/react";

const IntroSkeleton = () => {
  return (
    <div className="px-4 pb-4 text-base">
      <div className="mt-1 flex w-full flex-col gap-8 py-4">
        <div className="flex w-[307px] flex-col gap-4">
          <IonSkeletonText
            animated={true}
            className="m-0 h-12 w-full bg-neutral-400 bg-opacity-10"
          />
          <IonSkeletonText
            animated={true}
            className="m-0 h-12 w-[239px] bg-neutral-400 bg-opacity-10"
          />
        </div>
        <div className="flex w-[122px] flex-col gap-4">
          <IonSkeletonText
            animated={true}
            className="m-0 h-4 w-full bg-neutral-400 bg-opacity-10"
          />
          <IonSkeletonText
            animated={true}
            className="m-0 h-4 w-full bg-neutral-400 bg-opacity-10"
          />
        </div>
        <div className="flex flex-1 flex-col gap-4 border px-4 py-[18px]">
          <IonSkeletonText
            animated={true}
            className="m-0 h-4 w-[122px] bg-neutral-400 bg-opacity-10"
          />
          <IonSkeletonText
            animated={true}
            className="m-0 h-4 w-[122px] bg-neutral-400 bg-opacity-10"
          />
        </div>
      </div>
    </div>
  );
};

export default IntroSkeleton;
