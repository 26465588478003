import { CSSProperties, forwardRef, ReactNode } from "react";

import TransitionWrapper from "./TransitionWrapper";

type Props = {
  show: boolean;
  children: ReactNode;
  className?: string;
  delay?: number;
  style?: CSSProperties;
};

const TransitionFade = forwardRef<HTMLDivElement, Props>(
  function TransitionFade(
    { show, children, className, delay = 0, style },
    ref,
  ) {
    return (
      <TransitionWrapper
        ref={ref}
        show={show}
        className={className}
        delay={delay}
        isShownClass="opacity-100"
        isHiddenClass="opacity-0"
        style={style}
      >
        {children}
      </TransitionWrapper>
    );
  },
);

export default TransitionFade;
