import { Posthog } from "@capawesome/capacitor-posthog";
import { useEffect } from "react";

import { useUser } from "queries";

export const usePosthogIdentify = () => {
  const { data: user } = useUser();

  useEffect(() => {
    if (!!user) {
      Posthog.identify({ distinctId: String(user.id) });
    }
  }, [user]);
};
