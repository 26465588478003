import { createContext, Dispatch, SetStateAction } from "react";

import { Message } from "types/chat.types";

interface ChatbotUIContext {
  isLoading: boolean;
  sessionId: string;
  resetSession: () => void;
  userInput: string;
  setUserInput: Dispatch<SetStateAction<string>>;
  chatMessages: Array<Message>;
  setChatMessages: Dispatch<SetStateAction<Array<Message>>>;
  abortController: AbortController | null;
  setAbortController: Dispatch<SetStateAction<AbortController | null>>;
  firstTokenReceived: boolean;
  setFirstTokenReceived: Dispatch<SetStateAction<boolean>>;
  isGenerating: boolean;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
}

export const ChatbotUIContext = createContext<ChatbotUIContext>({
  isLoading: true,
  sessionId: "",
  resetSession: () => {},
  // CHAT STORE
  userInput: "",
  setUserInput: () => {},
  chatMessages: [],
  setChatMessages: () => {},
  isGenerating: false,
  setIsGenerating: () => {},
  firstTokenReceived: false,
  setFirstTokenReceived: () => {},
  abortController: null,
  setAbortController: () => {},
});
