import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Emotions } from "types/reaction.types";

import ReactionDivider from "./ReactionDivider";
import ReactionItem from "./ReactionItem";

type Props = {
  onAddReaction: (emotion: Emotions) => void;
  onRemoveReaction: (emotion: Emotions) => void;
  myReactions: Array<string>;
};

const ROWS_AMOUNT = 12;
const EMOTIONS_AMOUNT = Object.values(Emotions).length;
const EMPTY_BEFORE = Math.round((ROWS_AMOUNT - EMOTIONS_AMOUNT) / 2);
const EMPTY_AFTER = Math.floor((ROWS_AMOUNT - EMOTIONS_AMOUNT) / 2);

const ReactionsModal = ({
  onAddReaction,
  onRemoveReaction,
  myReactions,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    if (Capacitor.getPlatform() === "android") {
      StatusBar.setStyle({ style: Style.Dark });
      StatusBar.setBackgroundColor({ color: "#e63223" });
    }
  };
  const handleClose = () => {
    setIsOpen(false);
    if (Capacitor.getPlatform() === "android") {
      StatusBar.setStyle({ style: Style.Light });
      StatusBar.setBackgroundColor({ color: "#f4f5f8" });
    }
  };

  const handleAddReaction = (emotion: Emotions) => {
    onAddReaction(emotion);
    const timeoutClose = setTimeout(() => handleClose(), 750);
    return () => clearTimeout(timeoutClose);
  };
  const handleRemoveReaction = (emotion: Emotions) => {
    onRemoveReaction(emotion);
    const timeoutClose = setTimeout(() => handleClose(), 150);
    return () => clearTimeout(timeoutClose);
  };

  return (
    <>
      <button
        onClick={handleOpen}
        className="text-sm font-normal underline transition-all duration-200 active:bg-neutral-100/10"
      >
        <FormattedMessage id="common.react" />
      </button>

      <IonModal isOpen={isOpen}>
        <IonHeader mode="ios">
          <IonToolbar color="primary">
            <IonTitle className="text-sm font-medium uppercase text-white">
              <FormattedMessage id="common.react" />
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="primary" scrollY={false}>
          <div className="flex h-full max-h-full flex-col">
            {Array.from({ length: EMPTY_BEFORE }).map((_, index) => (
              <Fragment key={index}>
                <ReactionDivider
                  isOpen={isOpen}
                  initialWidth={((ROWS_AMOUNT - index) / ROWS_AMOUNT) * 100}
                />
                <div className="h-auto min-h-4 flex-1" />
              </Fragment>
            ))}
            {Object.values(Emotions).map((emotion, index) => (
              <Fragment key={`${EMPTY_BEFORE + index}`}>
                <ReactionDivider
                  isOpen={isOpen}
                  initialWidth={Math.abs(
                    ((ROWS_AMOUNT - (EMPTY_BEFORE + index)) / ROWS_AMOUNT) *
                      100,
                  )}
                />
                <ReactionItem
                  isOpen={isOpen}
                  emotion={emotion}
                  onAddReaction={() => handleAddReaction(emotion)}
                  onRemoveReaction={() => handleRemoveReaction(emotion)}
                  myReactions={myReactions}
                />
              </Fragment>
            ))}
            {Array.from({ length: EMPTY_AFTER }).map((_, index) => (
              <Fragment key={EMPTY_BEFORE + EMOTIONS_AMOUNT + index}>
                <ReactionDivider
                  isOpen={isOpen}
                  initialWidth={Math.abs(
                    ((ROWS_AMOUNT - (EMPTY_BEFORE + EMOTIONS_AMOUNT + index)) /
                      ROWS_AMOUNT) *
                      100,
                  )}
                />
                <div className="h-auto min-h-4 flex-1" />
                {index === EMPTY_AFTER - 1 && (
                  <ReactionDivider isOpen={isOpen} />
                )}
              </Fragment>
            ))}
          </div>
        </IonContent>
        <IonFooter mode="ios">
          <IonToolbar color="primary" className="!p-0">
            <IonButton
              onClick={handleClose}
              expand="block"
              className="flex items-center justify-center !p-0"
            >
              <IonText className="text-[32px] font-normal text-black">
                X
              </IonText>
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default ReactionsModal;
