import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  title: string;
};

const Wrapper = ({ children, title }: Props) => {
  return (
    <section
      className="relative flex flex-col bg-secondary text-primary"
      id="summary"
    >
      <div className="sticky top-0 z-50 w-full border-b border-primary bg-secondary px-4 text-xl">
        <h2 className="m-0 py-6 text-xl font-medium leading-[32px]">{title}</h2>
      </div>
      {children}
    </section>
  );
};

export default Wrapper;
