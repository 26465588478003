import { ScrollDetail } from "@ionic/react";
import { useState } from "react";

export const useShowHeaderTitleOnScroll = (scrollThreshold = 200) => {
  const [titleIsShown, setTitleIsShown] = useState(false);

  const handleScroll = (scrollEvent: CustomEvent<ScrollDetail>) => {
    const yScroll = scrollEvent.detail.currentY;
    setTitleIsShown(yScroll > scrollThreshold);
  };

  return {
    handleHeaderScroll: handleScroll,
    titleIsShown,
  };
};
