import { memo } from "react";
import Lottie from "react-lottie";

import LottieSplashAnimation from "assets/lottie/lottie-ubn-splash.json";

import { useWindowDimensions } from "hooks";
import { useIsAppLoaded } from "hooks/useIsAppLoaded";

const SplashScreen = () => {
  const { height } = useWindowDimensions();
  const { isLoaded: appIsLoaded } = useIsAppLoaded({ timeout: 2000 });

  if (appIsLoaded) return null;
  return (
    <div className="fixed inset-0 z-100 flex h-[100svh] w-[100svw] scale-[101%] items-center justify-center bg-white md:hidden">
      <Lottie
        height={height + 40}
        isStopped={appIsLoaded}
        options={{
          autoplay: true,
          loop: false,
          animationData: LottieSplashAnimation,
          rendererSettings: {
            className: "w-[100svw] h-[100svh]",
            progressiveLoad: true,
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
      />
    </div>
  );
};

export default memo(SplashScreen);
