export const isDev = () => process.env.REACT_APP_BUILD_ENV === "development";
export const isProd = () => process.env.REACT_APP_BUILD_ENV === "production";
export const isStag = () => process.env.REACT_APP_BUILD_ENV === "staging";
export const isTest = () => process.env.REACT_APP_BUILD_ENV === "test";

export const runInDev = (func: () => void) => (isDev() ? func() : null);
export const runInTest = (func: () => void) => (isTest() ? func() : null);
export const runNotInTest = (func: () => void) => (!isTest() ? func() : null);
export const runInProd = (func: () => void) => (isProd() ? func() : null);
export const runInStaging = (func: () => void) => (isStag() ? func() : null);
