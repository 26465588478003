import * as Sentry from "@sentry/react";
import { nanoid } from "nanoid";
import { useContext, useEffect, useRef } from "react";

import { consumeReadableStream } from "utils/chat.utils";

import { useSendChatMessage } from "queries";
import { ChatbotUIContext } from "services/ai-chatbot";
import { Message } from "types/chat.types";

export const createTempMessages = (
  messageContent: string,
  chatMessages: Array<Message>,
  setChatMessages: React.Dispatch<React.SetStateAction<Array<Message>>>,
) => {
  const tempUserChatMessage: Message = {
    id: nanoid(),
    content: messageContent,
    role: "user",
  };

  const tempAssistantChatMessage: Message = {
    id: nanoid(),
    role: "assistant",
    content: "",
  };

  let newMessages = [];

  newMessages = [
    ...chatMessages,
    tempUserChatMessage,
    tempAssistantChatMessage,
  ];

  setChatMessages(newMessages);

  return {
    tempUserChatMessage,
    tempAssistantChatMessage,
  };
};

export const processResponse = async (
  response: ReadableStream<Uint8Array>,
  lastChatMessage: Message,
  controller: AbortController,
  setFirstTokenReceived: React.Dispatch<React.SetStateAction<boolean>>,
  setChatMessages: React.Dispatch<React.SetStateAction<Array<Message>>>,
) => {
  let fullText = "";

  if (response) {
    await consumeReadableStream(
      response,
      (chunk: string) => {
        setFirstTokenReceived(true);
        try {
          fullText += chunk;
        } catch (error) {
          console.error("Error parsing chunk:", error);
          Sentry.setContext("chat-chunk", { chunk });
          Sentry.captureException(error);
        }

        setChatMessages((prev) =>
          prev.map((chatMessage) => {
            if (chatMessage.id === lastChatMessage.id) {
              const updatedChatMessage: Message = {
                ...chatMessage,
                content: fullText,
              };
              return updatedChatMessage;
            }
            return chatMessage;
          }),
        );
      },
      controller.signal,
    );

    return fullText;
  } else {
    throw new Error("Response body is null");
  }
};

export const useChatHandler = () => {
  const { sendChatMessage } = useSendChatMessage();

  const {
    sessionId: chatSessionId,
    setUserInput,
    setIsGenerating,
    setChatMessages,
    setFirstTokenReceived,
    abortController,
    setAbortController,
  } = useContext(ChatbotUIContext);

  const chatInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    chatInputRef.current?.focus();
  }, []);

  const handleFocusChatInput = () => {
    chatInputRef.current?.focus();
  };

  const handleStopMessage = () => {
    if (abortController) {
      abortController.abort();
    }
  };

  const handleSendMessage = async (
    messageContent: string,
    chatMessages: Array<Message>,
  ) => {
    const { tempUserChatMessage, tempAssistantChatMessage } =
      createTempMessages(messageContent, chatMessages, setChatMessages);
    try {
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      setIsGenerating(true);
      setUserInput("");

      const response = await sendChatMessage({
        messages: [...chatMessages, tempUserChatMessage],
        sessionId: chatSessionId,
      });

      await processResponse(
        response,
        tempAssistantChatMessage,
        newAbortController,
        setFirstTokenReceived,
        setChatMessages,
      );
    } catch (error) {
      console.error("Error in UBN AI", error);
      Sentry.setContext("chat-prompt", { messageContent });
      Sentry.captureException(error);
    } finally {
      setIsGenerating(false);
      setFirstTokenReceived(false);
      setAbortController(null);
    }
  };

  return {
    chatInputRef,
    handleSendMessage,
    handleFocusChatInput,
    handleStopMessage,
  };
};
