/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "./theme/variables.css";

import { App as CapacitorApp, BackButtonListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { Posthog } from "@capawesome/capacitor-posthog";
import {
  IonApp,
  isPlatform,
  setupIonicReact,
  useIonRouter,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { Suspense, useEffect } from "react";
import { generatePath } from "react-router";

import config from "config/env";

import { isDev } from "utils/env.utils";

import { Routes } from "constants/routes.constants";
import { useIsDesktop } from "hooks";
import { ChatProvider } from "services/ai-chatbot";
import { initializeAvo } from "services/avo";
import IntlSelectorProvider from "services/i18n";
import { createQueryClient, PersistOptions } from "services/react-query";

import RootBoundary from "components/@boundaries/RootBoundary";
import { ChatPopper } from "components/@chatbot";
import { SplashScreen } from "components/@common/SplashScreen";
import { AppRoutes } from "components/@routes/AppRoutes";

setupIonicReact({
  animated: !isPlatform("mobileweb"),
  hardwareBackButton: true,
  swipeBackEnabled: true,
  statusTap: true,
  scrollAssist: true,
  sanitizerEnabled: true,
});
const queryClient = createQueryClient();

initializeAvo();

function App() {
  const router = useIonRouter();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    const handleBackButton = ({ canGoBack }: BackButtonListenerEvent) => {
      if (
        !canGoBack ||
        router.routeInfo.pathname === generatePath(Routes.Home)
      ) {
        CapacitorApp.exitApp();
      } else {
        router.goBack();
      }
    };

    CapacitorApp.addListener("backButton", handleBackButton);

    if (Capacitor.isNativePlatform()) {
      ScreenOrientation.lock({ orientation: "portrait" });
    }

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [router]);

  useEffect(() => {
    try {
      Posthog.setup({
        apiKey: String(config.POSTHOG_KEY),
        host: config.POSTHOG_HOST,
      });
    } catch (error) {
      console.error("Error setting up Posthog", error);
    }
  }, []);

  return (
    <RootBoundary>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={PersistOptions}
      >
        <IonReactRouter>
          <SplashScreen />
          <IntlSelectorProvider>
            <RootBoundary>
              <ChatProvider>
                {Capacitor.isNativePlatform() ? (
                  <Suspense>
                    <IonApp>
                      <AppRoutes />
                    </IonApp>
                  </Suspense>
                ) : (
                  <IonApp>
                    <AppRoutes />
                    {isDesktop && <ChatPopper />}
                  </IonApp>
                )}
              </ChatProvider>
            </RootBoundary>
          </IntlSelectorProvider>
        </IonReactRouter>
        {isDev() && (
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        )}
      </PersistQueryClientProvider>
    </RootBoundary>
  );
}

export default App;
