import { Environment as EnvironmentDrei } from "@react-three/drei";

import envMap from "assets/discoball/bg.jpg";

const Environment = () => {
  return (
    <EnvironmentDrei
      files={[envMap]}
      environmentIntensity={1}
      resolution={64}
    />
  );
};

export default Environment;
