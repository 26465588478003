import { useEffect, useState } from "react";

import { chatbotSessionStart } from "services/avo/avo";

export const useSessionStorage = <T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(
    () =>
      JSON.parse(window.sessionStorage.getItem(key) || '""') || initialValue,
  );

  useEffect(() => {
    // Retrieve from sessionStorage
    const item = window.sessionStorage.getItem(key);
    if (item) {
      setStoredValue(JSON.parse(item));
    } else {
      // Save to sessionStorage

      window.sessionStorage.setItem(key, JSON.stringify(initialValue));

      chatbotSessionStart();
    }
  }, [initialValue, key]);

  const setValue = (value: T) => {
    // Save state
    setStoredValue(value);
    // Save to sessionStorage
    window.sessionStorage.setItem(key, JSON.stringify(value));
    chatbotSessionStart();
  };

  return [storedValue, setValue];
};
