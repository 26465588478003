import axios, { AxiosRequestConfig } from "axios";
import { camelizeKeys, decamelizeKeys } from "fast-case";

import interceptors from "./interceptors";

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: { Accept: "application/vnd.api+json" },
  transformRequest: [
    (data) => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === "object") {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== "object" && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          // Prevent converting presigned url data, so it can be passed with the keys unchanged to AWS
          if (!("AWSAccessKeyId" in data)) {
            data = camelizeKeys(data);
          }
        }
        return data;
      } catch (error) {
        return camelizeKeys(data);
      }
    },
  ],
};

interface Props {
  enableInterceptors?: boolean;
  enableAuthHeader?: boolean;
  requestConfig?: AxiosRequestConfig;
}

const createManager = ({
  enableInterceptors = true,
  enableAuthHeader = true,
  requestConfig,
}: Props) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });

  manager.interceptors.request.use(
    async (axiosConfig) => axiosConfig,
    (error) => Promise.reject(error),
  );

  if (enableInterceptors)
    interceptors.forEach((interceptor) =>
      interceptor(manager, enableAuthHeader),
    );

  return manager;
};

export { createManager };
