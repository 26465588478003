import * as Sentry from "@sentry/react";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { useCopyToClipboard } from "hooks";

interface Props {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  fullscreen?: boolean;
  resetError(): void;
}

const RootBoundary = ({ children }: { children: ReactNode }) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag("boundary", "root")}
    fallback={(props) => <Fallback {...props} error={props.error as Error} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

const Fallback = ({ error, componentStack }: Props) => {
  const message = error?.message;

  const { copy, isCopied } = useCopyToClipboard();

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleCopy = () => {
    copy(`${message}\n${componentStack}`);
  };

  return (
    <div className="flex h-[100svh] w-full items-center justify-center bg-neutral-50">
      <div className="flex max-w-sm flex-col gap-6 rounded-lg bg-white p-8">
        <div className="flex flex-col gap-4">
          <h1 className="text-center text-lg font-semibold text-neutral">
            <FormattedMessage id="error.something_went_wrong" />
          </h1>
          <p className="text-center text-sm text-neutral-100">
            <FormattedMessage id="error.send_it_team" />
          </p>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-4">
          <button
            onClick={handleRefresh}
            className="flex w-fit cursor-pointer items-center gap-2 border-none bg-transparent text-neutral-100"
          >
            <p className="m-0 text-sm font-semibold">
              <FormattedMessage id="error.refresh_page" />
            </p>
          </button>

          <button
            onClick={handleCopy}
            className="flex w-fit cursor-pointer items-center gap-2 border-none bg-transparent text-neutral-100"
          >
            <p className="m-0 text-sm font-semibold">
              <FormattedMessage
                id={isCopied ? "common.copied" : "error.copy_to_clipboard"}
              />
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RootBoundary;
