import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import { objectKeys } from "utils/common.utils";
import { captureError } from "utils/sentry.utils";

import { SpeakerQueries } from "queries/speaker/queries";

export const usePrefetch = () => {
  const didPrefetch = useRef(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (didPrefetch.current) return;

    didPrefetch.current = true;

    const prefetch = async () => {
      try {
        const speakersByDay = await queryClient.ensureQueryData(
          SpeakerQueries.getSpeakers(),
        );

        objectKeys(speakersByDay).forEach((day) => {
          const speakers = speakersByDay[day];

          speakers.forEach((speaker) =>
            queryClient.prefetchQuery(
              SpeakerQueries.getSpeakerById(speaker.id.toString()),
            ),
          );
        });
      } catch (error) {
        if (error instanceof Error) {
          captureError(`Failed to prefetch queries`, error);
        }
      }
    };

    prefetch();
  }, [queryClient]);
};
