import { useIonRouter } from "@ionic/react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router";

import { ENABLE_LOGIN } from "constants/app.constants";
import { Routes } from "constants/routes.constants";
import { User } from "types/user.types";

import { useToastNotification } from "./useToastNotification";

type Props = {
  loginAction: () => void;
  errorMessage?: I18nKey;
  user?: User;
};

export const useCheckLoginAction = ({
  loginAction,
  errorMessage = "community.reflections.post.not_logged_in",
  user,
}: Props) => {
  const intl = useIntl();
  const router = useIonRouter();

  const errorToast = useToastNotification({
    message: intl.formatMessage({ id: errorMessage }),
    color: "primary",
    buttons: [
      {
        text: intl.formatMessage({ id: "common.login" }),
        handler: () => router.push(generatePath(Routes.Onboarding), "forward"),
        htmlAttributes: { style: { fontSize: "16px" } },
      },
    ],
  });

  const checkLogin = () => {
    if (!ENABLE_LOGIN) return;
    if (!!user) {
      loginAction();
    } else {
      errorToast.presentToast();
    }
  };

  return checkLogin;
};
