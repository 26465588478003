import { SpeakerResponse } from "types/speaker.types";

type Props = {
  socials: SpeakerResponse["socials"];
};

const Socials = ({ socials }: Props) => {
  return (
    <div className="flex flex-col gap-4 text-base underline pb-safe">
      {Object.entries(socials).map(([key, value], idx) => {
        if (!value) return null;
        return (
          <a
            key={idx}
            className="capitalize text-neutral"
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            {key}
          </a>
        );
      })}
    </div>
  );
};

export default Socials;
